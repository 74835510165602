import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const AboutSection = ({ content, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.2 }}
    className="mb-12"
  >
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </motion.div>
);

const Apropos = () => {
  const apropos = useSelector(state => state?.data?.apropos);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (apropos?.contenue) {
      // Split content into sections (assuming h2 tags as section breaks)
      const splitSections = apropos.contenue.split(/<h2/i).filter(Boolean);
      setSections(splitSections.map(section => `<h2${section}`));
    }
  }, [apropos]);

  return (
    <div className="bg-gradient-to-b from-gray-100 to-white min-h-screen py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl sm:text-5xl font-bold text-center text-gray-800 mb-12"
        >
          À Propos de Nous
        </motion.h1>
        <div className="max-w-3xl mx-auto">
          {sections.map((section, index) => (
            <AboutSection key={index} content={section} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Apropos;