import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetPassword } from "../../../actions/data";
import { toast, Toaster } from "react-hot-toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function PasswordProfile() {
  const client = useSelector((state) => state?.auth?.client);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    specialChar: false,
    numeric: false,
  });
  const dispatch = useDispatch();

  const validatePassword = (password) => {
    setPasswordCriteria({
      length: password?.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>_]/.test(password),
      numeric: /[0-9]/.test(password),
    });
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordCriteria.length || !passwordCriteria.specialChar || !passwordCriteria.numeric) {
      toast.error("Le nouveau mot de passe ne respecte pas les critères requis !");
      return;
    }
    if(newPassword !== confirmPassword){
      toast.error("Les mots de passe ne sont pas compatibles !");
      return;
    }
    dispatch(ResetPassword({
      oldPassword: currentPassword,
      newPassword: newPassword,
      id: client?.id
    })).then((result) => {
      console.log(result);
      if(result){
        e.target.reset();
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordCriteria({
          length: false,
          specialChar: false,
          numeric: false,
        });
        setShowCurrentPassword(false);
        setShowConfirmPassword(false);
        setShowNewPassword(false);
      }else{

      }
    }).catch((error) => {
      console.error("Error:", error);
    });;
  };

  return (
    <div className="dashboard-right-contents mt-4 mt-lg-0">
      <div className="dashboard-reservation">
        <div className="single-reservation bg-white base-padding">
          <h3 className="single-reservation-title">Changer le mot de passe</h3>
          <div className="custom--form dashboard-form">
            <form onSubmit={handleSubmit}>
              <div className="dashboard-input mt-4">
                <label htmlFor="ancien" className="label-title">Mot de passe actuel</label>
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  className="form--control"
                  placeholder="Mot de passe actuel"
                  id="ancien"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required={true}
                />
                <div
                  className="toggle-password"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  <span className="">
                    {showCurrentPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </span>
                </div>
              </div>
              <div className="dashboard-input mt-4">
                <label htmlFor="new" className="label-title">Nouveau mot de passe</label>
                <input
                  type={showNewPassword ? "text" : "password"}
                  className="form--control"
                  placeholder="Nouveau mot de passe"
                  id="new"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  required={true}
                />
                <div
                  className="toggle-password"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  <span className="">
                    {showNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </span>
                </div>
              </div>
              <div className="dashboard-input mt-4">
                <label htmlFor="confirme" className="label-title">Confirmer le mot de passe</label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form--control"
                  placeholder="Confirmer le mot de passe"
                  id="confirme"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required={true}
                />
                <div
                  className="toggle-password"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <span className="">
                    {showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </span>
                </div>
              </div>
              <div className="dashboard-checkbox-wrapper">
                <div className="dashboard-checkbox check-success mt-3">
                  <input
                    className="check-input"
                    type="checkbox"
                    id="character"
                    checked={passwordCriteria?.length}
                    readOnly
                  />
                  <label className="checkbox-label" htmlFor="character">
                    Le mot de passe doit comporter au moins 8 caractères
                  </label>
                </div>
                <div className="dashboard-checkbox check-success mt-3">
                  <input
                    className="check-input"
                    type="checkbox"
                    id="special"
                    checked={passwordCriteria?.specialChar}
                    readOnly
                  />
                  <label className="checkbox-label" htmlFor="special">
                    Doit contenir au moins un caractère spécial
                  </label>
                </div>
                <div className="dashboard-checkbox check-success mt-3">
                  <input
                    className="check-input"
                    type="checkbox"
                    id="numeric"
                    checked={passwordCriteria?.numeric}
                    readOnly
                  />
                  <label className="checkbox-label" htmlFor="numeric">
                    Doit contenir au moins une valeur numérique
                  </label>
                </div>
              </div>
              <div className="btn-wrapper mt-4">
                <button type="submit" className="cmn-btn btn-bg-1">
                  Enregistrer les modifications
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster/>
    </div>
  );
}

export default PasswordProfile;
