import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ReviewFilter = ({ selectedService, setSelectedService, services }) => {

  const handleServiceChange = (e) => {
    setSelectedService(e.target.value);
  };

  return (
    <div className="filter">
      <FormControl sx={{ m: 1, width: "95%" }}>
        <Select
          value={selectedService}
          onChange={handleServiceChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            fontFamily: "CustomFont, sans-serif",
            backgroundColor: "#fff",
          }}
        >
          <MenuItem
            sx={{
              fontFamily: "CustomFont, sans-serif",
            }}
            value="All"
          >
            Toutes les prestations
          </MenuItem>
          {services?.map(ser=>(
            <MenuItem
              key={ser?.id}
              sx={{ fontFamily: "CustomFont, sans-serif" }}
              value={ser?.id}
            >
              {ser?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ReviewFilter;
