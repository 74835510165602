import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { CustomRoute, ScrollToTop, SignInEntreprise } from "../components";
import {
  AfterPannier,
  Apropos,
  BlogPage,
  CheckoutPage,
  Conditions,
  Dashboard,
  DetailBlogPage,
  DetailPage,
  Entreprise,
  GridListing,
  Login,
  Politique,
  Register,
  Rgbd,
  SecondPage,
  SideBarListing,
} from "../pages";
import { AuthProvider, useAuth } from "../context/authContext";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchData } from "../actions/data";
import { ACCUEIL, APROPOS, BLOGS, BOOKINGS, CONDITIONS, ENTREPRISE, FAQS, FEEDBACKS, FOOTER, GALERIE, POLITIQUES, REASONS, RGBD, SALONS, SERVICES, SUGGESTIONS } from "../constants/actions";

const PrivateRoute = () => {
  const { isClientAuthenticated } = useAuth();
  return isClientAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

const RedirectUserIfAuthenticated = ({ children }) => {
  const { isClientAuthenticated } = useAuth();
  return isClientAuthenticated ? <Navigate to="/" replace /> : children;
};

const MainNavigator = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uris = [
          { uri: `/accueil`, action: ACCUEIL },
          { uri: `/services`, action: SERVICES },
          { uri: `/completedSalons`, action: SALONS },
          { uri: `/activeSuggestions`, action: SUGGESTIONS },
          { uri: `/faqs`, action: FAQS },
          { uri: `/reasons`, action: REASONS },
          { uri: `/feedbacks`, action: FEEDBACKS },
          { uri: `/footer`, action: FOOTER },
          { uri: `/propos`, action: APROPOS },
          { uri: `/blogs`, action: BLOGS },
          { uri: `/entreprise`, action: ENTREPRISE },
          { uri: `/galerie`, action: GALERIE },
          { uri: `/conditions`, action: CONDITIONS },
          { uri: `/politiques`, action: POLITIQUES },
          { uri: `/rgbd`, action: RGBD },
        ];

        await Promise.all(
          uris.map(({ uri, action }) => dispatch(FetchData(uri, action)))
        );
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uris = [
          { uri: `/clientBookings/${auth?.client?.id}`, action: BOOKINGS },
        ];
  
        await Promise.all(
          uris.map(({ uri, action }) => dispatch(FetchData(uri, action)))
        );
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    if(auth?.isClientAuth && auth?.client?.id){
      fetchData();
    }
  }, [dispatch, auth?.isClientAuth]);
  
  return (
    <AuthProvider>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <CustomRoute
                path="/"
                showheader
                showfooter
                component={SecondPage}
              />
            }
          />
          <Route
            path="/second-landingpage"
            element={
              <CustomRoute
                path="/second-landingpage"
                showheader
                showfooter
                component={SecondPage}
              />
            }
          />
          <Route
            path="/grid-page"
            element={
              <CustomRoute
                path="/grid-page"
                showheader
                showfooter
                component={GridListing}
              />
            }
          />
          <Route
            path="/sidelisting-page"
            element={
              <CustomRoute
                path="/sidelisting-page"
                showheader
                showfooter
                component={SideBarListing}
              />
            }
          />
          <Route
            path="/info-apropos"
            element={
              <CustomRoute
                path="/info-apropos"
                showheader
                showfooter
                component={Apropos}
              />
            }
          />
          <Route
            path="/conditions"
            element={
              <CustomRoute
                path="/conditions"
                showheader
                showfooter
                component={Conditions}
              />
            }
          />
          <Route
            path="/politique"
            element={
              <CustomRoute
                path="/politique"
                showheader
                showfooter
                component={Politique}
              />
            }
          />
          <Route
            path="/rgbd"
            element={
              <CustomRoute
                path="/rgbd"
                showheader
                showfooter
                component={Rgbd}
              />
            }
          />
          <Route
            path="/connexion"
            element={
              <RedirectUserIfAuthenticated>
                <CustomRoute
                  path="/connexion"
                  showheader
                  showfooter
                  component={Login}
                />
              </RedirectUserIfAuthenticated>
            }
          />
          <Route
            path="/register"
            element={
              <RedirectUserIfAuthenticated>
                <CustomRoute
                  path="/register"
                  showheader
                  showfooter
                  component={Register}
                />
              </RedirectUserIfAuthenticated>
            }
          />

          <Route
            path="/entreprise"
            element={<CustomRoute path="/entreprise" component={Entreprise} />}
          />
          <Route element={<PrivateRoute />}>
            <Route
              path="/dashboard"
              element={
                <CustomRoute
                  path="/dashboard"
                  showheader
                  showfooter
                  component={Dashboard}
                />
              }
            />
          </Route>
          <Route
            path="/sign-in"
            element={
              <CustomRoute path="/sign-in" component={SignInEntreprise} />
            }
          />
          <Route
            path="/detail-page"
            element={
              <CustomRoute
                path="/detail-page"
                showheader
                // showfooter
                component={DetailPage}
              />
            }
          />
          <Route
            path="/availability"
            element={
              <CustomRoute
                path="/availability"
                showheadermin
                component={AfterPannier}
              />
            }
          />
          <Route
            path="/checkout"
            element={
              <CustomRoute
                path="/checkout"
                showheadermin
                component={CheckoutPage}
              />
            }
          />
          <Route
            path="/blog"
            element={
              <CustomRoute
                path="/blog"
                showheader
                showfooter
                component={BlogPage}
              />
            }
          />
          <Route
            path="/blog-detail/:id"
            element={
              <CustomRoute
                path="/blog-detail/:id"
                showheader
                showfooter
                component={DetailBlogPage}
              />
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default MainNavigator;
