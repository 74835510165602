import '../../assets/css/bootstrap.min.css';
import '../../assets/css/nice-select.css';
import './style.scss';
import '../../assets/css/style.css';
import { Link } from 'react-router-dom';
import logo from "../../assets/img/skipp-logo1.jpg";
// import React, { useState } from 'react';
// import { useTranslation } from "react-i18next";
// import '../../assets/css/animate.css';
// import '../../assets/css/flatpicker.css';
// import '../../assets/css/intlTelInput.css';
// import '../../assets/css/line-awesome.min.css';
// import '../../assets/css/magnific-popup.css';
// import '../../assets/css/slick.css';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function HeaderMinimal() {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isBtnsOpen, setIsBtnsOpen] = useState(false);
  // const location = useLocation();
  // const { t } = useTranslation();

  // const handleMenuToggle = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  // const handleBtnsToggle = () => {
  //   setIsBtnsOpen(!isBtnsOpen);
  // };

  return (
    <nav className="navbar navbar-area navbar-border navbar-padding navbar-expand-lg">
      <div className="container custom-container-one nav-container">
        <div className="logo-wrapper">
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default HeaderMinimal;