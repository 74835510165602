import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

function Politique() {
    const politiques = useSelector(state => state?.data?.politiques);

  return (
    <div className="apropos-page">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: politiques?.contenue }} />
      </div>
    </div>
  );
}

export default Politique;
