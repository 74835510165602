import { apiClient } from "../actions/api";

const UploadImage = async (
  imageField,
  imageData,
  oldImageData = "",
  folder
) => {
  const formData = new FormData();
  formData.append("image", imageData[imageField]);
  formData.append("folder", folder);
  if (oldImageData[imageField]) {
    formData.append("old_image_path", oldImageData[imageField]);
  }

  try {
    const { data } = await apiClient().post("/upload", formData);
    return data.path;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export default UploadImage;
