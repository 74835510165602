import React, { useEffect, useState } from "react";
import shapes from "../../assets/img/banner/banner-shapes.png";
import shapes2 from "../../assets/img/banner/banner-shapes2.png";
import bannerwoman2 from "../../assets/img/banner/woman2.png";
import imgshape from "../../assets/img/banner/img-shape1.svg";
import "./style.scss";
import LocationArea from "../locationarea";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL_IMAGE } from "../../constants/actions";

function BannerTwo() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const data = useSelector((state) => state?.data) || [];
  const accueil = useSelector(state => state?.data?.accueil);
  

  const villes =
    [...new Set(data?.salons?.map((salon) => salon?.city?.toLowerCase()))] ||
    [];

  const [location, setLocation] = useState("");
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [person, setPerson] = useState("");

  const [nomEtablissement, setNomEtablissement] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearch = (tab) => {
    if(tab === 1){
      navigate('/sidelisting-page', {
        state:{
          service: person,
          city: location,
          date: checkOutDate,
        }
      })
    }else{
      if(nomEtablissement === ""){
        toast.error('Veuillez entrer un nom d’établissement !');
      }else{
        navigate('/sidelisting-page', {
          state:{
            searchInput: nomEtablissement
          }
        })
      }
    }
  };

  return (
    <>
      {screenWidth > 990 ? (
        <div className="bannertwo">
          <div className="banner-area banner-area-two banner-bg">
            <div className="banner-shapes">
              <img src={shapes} alt="shapes" />
              <img src={shapes2} alt="shapes" />
            </div>
            <div className="container">
              <div className="banner-area-flex">
                <LocationArea
                  locationOptions={villes}
                  personOptions={data?.services}
                  person={person}
                  setPerson={setPerson}
                  location={location}
                  setLocation={setLocation}
                  checkOutDate={checkOutDate}
                  setCheckOutDate={setCheckOutDate}
                  nomEtablissement={nomEtablissement}
                  setNomEtablissement={setNomEtablissement}
                  handleSearch={handleSearch}
                />
                <div className="banner-thumb-wrap">
                  <div className="banner-area-thumb">
                    <img src={BASE_URL_IMAGE + accueil?.imageBanner} alt="banner image" />
                  </div>
                  <div className="banner-thumb-shape">
                    <img src={imgshape} alt="shapes" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bannertwo">
          <div className="banner-area banner-area-two banner-bg">
            <div className="banner-shapes">
              <img src={shapes} alt="shapes" />
              <img src={shapes2} alt="shapes" />
            </div>
            <div className="container">
              <div className="banner-area-flex">
                <div className="banner-thumb-wrap">
                  <div className="banner-area-thumb">
                    <img src={BASE_URL_IMAGE + accueil?.imageBanner} alt="banner image" />
                  </div> 
                  <div className="banner-thumb-shape">
                    <img src={imgshape} alt="shapes" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LocationArea
            locationOptions={villes}
            personOptions={data?.services}
            person={person}
            setPerson={setPerson}
            location={location}
            setLocation={setLocation}
            checkOutDate={checkOutDate}
            setCheckOutDate={setCheckOutDate}
            nomEtablissement={nomEtablissement}
            setNomEtablissement={setNomEtablissement}
            handleSearch={handleSearch}
          />
        </div>
      )}
    </>
  );
}

export default BannerTwo;
