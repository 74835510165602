// import { useState, useEffect } from 'react';

// // Popup Component
// const DownloadAppPopup = () => {
//       const [isModalVisible, setIsModalVisible] = useState(false);
    
//       // Show the modal only on mobile devices
//       const isMobile = () => {
//         return window.innerWidth <= 768;
//       };
    
//       useEffect(() => {
//         // Check if 7 days have passed since the last popup
//         const lastModalDate = localStorage.getItem('appModalClosedDate');
//         const sevenDays = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
//         const now = new Date().getTime();
    
//         if (lastModalDate && now - lastModalDate < sevenDays) {
//           // Do not show the modal if it has been shown in the last 7 days
//           return;
//         }
    
//         // Show modal after 10 seconds if on mobile
//         if (isMobile()) {
//           const timeout = setTimeout(() => {
//             setIsModalVisible(true);
//           }, 10000); // 10 seconds delay
    
//           return () => clearTimeout(timeout);
//         }
//       }, []);
    
//       // Function to close the modal and set the date for next show (after 7 days)
//       const closeModal = () => {
//         setIsModalVisible(false);
//         const now = new Date().getTime();
//         localStorage.setItem('appModalClosedDate', now);
//       };
    
//       return (
//         <>
//           {isModalVisible && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//               <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4 text-center">
//                 <button
//                   className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-xl"
//                   onClick={closeModal}
//                 >
//                   &times;
//                 </button>
//                 <h2 className="text-xl font-bold mb-4">Téléchargez notre application</h2>
//                 <p className="mb-6">Obtenez notre application mobile pour une meilleure expérience !</p>
//                 <div className="flex justify-center space-x-4">
//                   <a
//                     href="https://play.google.com/"
//                     target="_blank"
//                     className="bg-blue-600 text-white px-4 py-2 rounded-lg"
//                     rel="noreferrer"
//                   >
//                     Google Play
//                   </a>
//                   <a
//                     href="https://apps.apple.com/"
//                     target="_blank"
//                     className="bg-gray-800 text-white px-4 py-2 rounded-lg"
//                     rel="noreferrer"
//                   >
//                     App Store
//                   </a>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       );
//     };
    

// export default DownloadAppPopup;

import React, { useState, useEffect } from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import CloseIcon from '@mui/icons-material/Close';

// Popup Component
const DownloadAppPopup = () => {
    
    
      const [isVisible, setIsVisible] = useState(false);
    
      useEffect(() => {
        const checkModalVisibility = () => {
          const lastShown = localStorage.getItem('appModalLastShown');
          const now = new Date().getTime();
          const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
    
          if (!lastShown || now - parseInt(lastShown) > sevenDaysInMs) {
            setIsVisible(true);
            localStorage.setItem('appModalLastShown', now.toString());
          }
        };
    
        const handleResize = () => {
          if (window.innerWidth <= 768) {
            checkModalVisibility();
          } else {
            setIsVisible(false);
          }
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
      const closeModal = () => {
        setIsVisible(false);
      };
    
      if (!isVisible) return null;
    
      return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">Téléchargez Skipp</h2>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                <CloseIcon size={24} />
              </button>
            </div>
            <p className="text-gray-600 mb-6">
              Profitez d'une expérience optimisée avec Skipp et restez connecté où que vous soyez. Téléchargez notre application dès maintenant !
            </p>
            <div className="space-y-3">
              <a
                href="#"
                className="flex items-center justify-center w-full bg-[#f79d68] hover:bg-[#f58d4f] text-white font-semibold py-2 px-4 rounded text-center transition duration-300"
              >
                <AppleIcon className="mr-2" size={20} />
                Télécharger sur l'App Store
              </a>
              <a
                href="#"
                className="flex items-center justify-center w-full bg-[#f79d68] hover:bg-[#f58d4f] text-white font-semibold py-2 px-4 rounded text-center transition duration-300"
              >
                <ShopIcon className="mr-2" size={20} />
                Obtenir sur Google Play
              </a>
            </div>
          </div>
        </div>
      );
    };
    

export default DownloadAppPopup;