import React, { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss"; // Make sure to adjust the styles here
import SearchPart from "../searchpart";
import SearchPart2 from "../searchpart2";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import StorefrontIcon from "@mui/icons-material/Storefront";

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function LocationArea(props) {
  const [value, setValue] = useState(0);

  const handleTabClick = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className="location-area">
      <div className="tabs-container">
        <div className="tabs">
          <button
            className={`tab-button ${value === 0 ? "active" : ""}`}
            onClick={() => handleTabClick(0)}
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
            aria-selected={value === 0}
          >
            <span className="tab-icon">
              <ContentCutIcon />
            </span>
            Prestation
          </button>
          <button
            className={`tab-button ${value === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
            aria-selected={value === 1}
          >
            <span className="tab-icon">
              <StorefrontIcon />
            </span>
            Nom de l'établissement
          </button>
        </div>
      </div>
      <CustomTabPanel value={value} index={0}>
        <SearchPart props={props} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SearchPart2 props={props} />
      </CustomTabPanel>
    </div>
  );
}

export default LocationArea;