import './style.scss'
import { BannerTwo, DownloadApp, Faqs, Suggestions, TopSalon, Whattheysay, Whyus } from '../../components'
import { Toaster } from "react-hot-toast";
import { useSelector } from 'react-redux'

function SecondPage() {
  const accueil = useSelector(state => state?.data?.accueil);
  return (
    <div className='second-page'>
      <BannerTwo />
      <TopSalon title={accueil?.titleSalons} topSalons={accueil?.topSalons} />
      <Whyus title={accueil?.titleWhyUs} />
      <Whattheysay title={accueil?.titleFeedbacks} />
      <Suggestions title={accueil?.titleCategories} />
      <Faqs title={accueil?.titleFaqs} />
      <DownloadApp title={accueil?.titleApp} description={accueil?.descriptionApp} />
      <Toaster/>
    </div>
  )
}

export default SecondPage