import { format, parse } from 'date-fns';
import { fr } from 'date-fns/locale';

const FormateDateRange = (datePart, startTime, endTime) => {
  const startDate = parse(`${datePart} ${startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
  const endDate = parse(`${datePart} ${endTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());

  const formattedDate = format(startDate, "EEEE d MMMM yyyy", { locale: fr });
  const formattedStartTime = format(startDate, "h:mm a", { locale: fr });
  const formattedEndTime = format(endDate, "h:mm a", { locale: fr });

  return `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;
}

export default FormateDateRange;