import React, { useEffect, useState } from "react";
import guestshape from "../../assets/img/guest/guest-shape.png";
import { useSelector } from "react-redux";
import { BASE_URL_IMAGE } from "../../constants/actions";

function Whattheysay({ title }) {
  const guestData = useSelector(state => state?.data?.feedbacks)

  const [activeGuest, setActiveGuest] = useState(0);

  const generateRandomNumber = () => {
    const newNumber = Math.floor(Math.random() * 6) + 1;
    setActiveGuest(newNumber);
  };

  useEffect(() => {
    generateRandomNumber();
  }, []);

  const [hoveredGuest, setHoveredGuest] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredGuest(id);
  };

  const handleMouseLeave = () => {
    setHoveredGuest(null);
  };

  const handleClick = (id) => {
    setActiveGuest(id);
  };

  return (
    <section className="guest-area pat-50 pab-50">
      <div className="container">
        <div className="section-title center-text">
          <h2 className="title"> {title} </h2>
          <div className="section-title-line"></div>
        </div>
        <div className="custom-tab guest-wrapper mt-5">
          <div className="guest-wrapper-shapes">
            <img src={guestshape} alt="img" />
          </div>
          <div className="custom-tab-menu">
            <ul className="tab-menu guest-wrapper-images list-style-none">
              {guestData?.map((guest) => (
                <li
                  key={guest?.id}
                  className={`guest-wrapper-images-single ${
                    activeGuest === guest?.id ? "active" : ""
                  } ${hoveredGuest === guest?.id ? "hovered" : ""}`}
                  onMouseEnter={() => handleMouseEnter(guest?.id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(guest?.id)}
                >
                  <img src={BASE_URL_IMAGE + guest?.avatar} alt="img" />
                </li>
              ))}
            </ul>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-lg-6 col-md-8">
              <div className="tab-area">
                {guestData?.map((guest) => (
                  <div
                    key={guest?.id}
                    className={`single-guest tab-item center-text ${
                      activeGuest === guest?.id ? "active" : ""
                    }`}
                    style={{
                      display: activeGuest === guest?.id ? "block" : "none",
                    }}
                  >
                    <div className="single-guest-thumb">
                      <img src={BASE_URL_IMAGE + guest?.avatar} alt="img" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para" dangerouslySetInnerHTML={{ __html: guest?.description }}/>
                      <h4 className="single-guest-contents-title mt-3">
                        {guest?.name}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whattheysay;
