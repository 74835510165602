import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { loginClient } from "../../actions/auth";
import { apiClient } from "../../actions/api";
import { useNavigate } from "react-router-dom";
import "./style.scss";

function Login() {
  const [formData, setFormData] = useState({});
  const [resetStep, setResetStep] = useState('login');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [codeVerification, setCodeVerification] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginClient(formData)).then((response) => {
      if (response.success) {
        toast.success("Connexion réussie !");
        navigate("/home");
      } else {
        toast.error("Échec de connexion: " + response.message);
      }
    });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      apiClient().post("/forgot-password", { email: forgotPasswordEmail })
      .then(response => {
        if (response?.data?.success) {
          toast.success("Un e-mail de réinitialisation a été envoyé à votre adresse.");
          setResetStep('enterCode');
        } else {
          toast.error("E-mail n'existe pas");
        }
      })
      
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.");
      console.error(error);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      apiClient().post("/check-code", { email: forgotPasswordEmail, resetNumber: codeVerification })
      .then(response => {
        if (response?.data?.status) {
          toast.success("Code vérifié avec succès !");
          setResetStep('newPassword');
        } else {
          toast.error("Le code de réinitialisation est incorrect");
          setResetStep('enterCode');
        }
        
      })
      
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.");
      console.error(error);
    }
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPass) {
      toast.error("Les mots de passe ne correspondent pas !");
      return;
    }

    try {
      const response = await apiClient().post("/reset-password", {
        email: forgotPasswordEmail,
        password: formData.password
      });
      if (response.data.status) {
        toast.success("Mot de passe réinitialisé avec succès !");
        setResetStep('login');
        navigate('/connexion')
      } else {
        toast.error("Échec de la réinitialisation du mot de passe : " + response.data.message);
      }
    } catch (error) {
      toast.error("Une erreur s'est produite. Veuillez réessayer.");
      console.error(error);
    }
  };

  const renderForm = () => {
    switch (resetStep) {
      case 'login':
        return (
          <form onSubmit={handleSubmit}>
            <h4>Connectez-vous ici</h4>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              placeholder="Email"
              id="email"
              value={formData?.email || ""}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required={true}
            />

            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              placeholder="Mot de passe"
              id="password"
              value={formData?.password || ""}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required={true}
            />

            <span
              className="forget-pass"
              onClick={() => setResetStep('forgotPassword')}
            >
              Mot de passe oublié ?
            </span>
            <button type="submit">Connexion</button>
          </form>
        );
      case 'forgotPassword':
        return (
          <form onSubmit={handleForgotPassword}>
            <h4>Réinitialisation du mot de passe</h4>
            <label htmlFor="forgotPasswordEmail">Email</label>
            <input
              type="email"
              placeholder="Entrez votre email"
              id="forgotPasswordEmail"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              required={true}
            />
            <button type="submit">Envoyer</button>
            <span 
              className="back-to-login"
              onClick={() => setResetStep('login')}
            >
              Retour à la connexion
            </span>
          </form>
        );
      case 'enterCode':
        return (
          <form onSubmit={handleVerifyCode}>
            <h4>Entrez le code de réinitialisation</h4>
            <label htmlFor="code">Code de réinitialisation</label>
            <input
              type="text"
              placeholder="Entrez le code"
              id="code"
              value={codeVerification}
              onChange={(e) => setCodeVerification(e.target.value)}
              required={true}
            />
            <button type="submit">Vérifier</button>
          </form>
        );
      case 'newPassword':
        return (
          <form onSubmit={handleNewPasswordSubmit}>
            <h4>Nouveau mot de passe</h4>
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              placeholder="Mot de passe"
              id="password"
              value={formData?.password || ""}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required={true}
            />
            <label htmlFor="confirmPassword">Confirmer mot de passe</label>
            <input
              type="password"
              placeholder="Confirmer mot de passe"
              id="confirmPassword"
              value={formData?.confirmPass || ""}
              onChange={(e) => setFormData({ ...formData, confirmPass: e.target.value })}
              required={true}
            />
            <button type="submit">Envoyer</button>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="background">
          <div className="shape"></div>
          <div className="shape"></div>
        </div>
        {renderForm()}
      </div>
      <Toaster />
    </div>
  );
}

export default Login;