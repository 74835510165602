function FormatDateToFrensh(dateString) {
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  const [year, month, day] = dateString.split("-");
  const monthIndex = parseInt(month, 10) - 1;
  const formattedMonth = months[monthIndex];

  return `${formattedMonth}, ${parseInt(day, 10)} ${year}`;
}

export default FormatDateToFrensh;