import React, { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { registerClient } from "../../actions/auth";
import "./style.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmePassword: "",
  });
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData?.password !== formData?.confirmePassword) {
      toast.error("Les mots de passe ne sont pas compatibles");
    } else {
      dispatch(registerClient(formData)).then((result) => {
        if (result.success) {
            toast.success("Inscription terminée avec succès !");
        } else {
            toast.error("Échec de l'inscription : " + result.message);
        }
    });      
    }
  };

  return (
    <div className="register-page">
      <div className="container">
        <div className="background">
          <div className="shape"></div>
          <div className="shape"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <h4>Inscrivez-vous ici</h4>

          <div className="input-flex-item">
            <div className="single-input">
              <label htmlFor="first_name" className="label-title mb-3">
                Prénom
              </label>
              <input
                className="form--control"
                type="text"
                id="first_name"
                name="first_name"
                placeholder="Tapez votre prénom"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="single-input">
              <label htmlFor="last_name" className="label-title mb-3">
                Nom
              </label>
              <input
                className="form--control"
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Tapez votre nom"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-flex-item">
            <div className="single-input">
              <label htmlFor="email" className="label-title mb-3">
                Adresse e-mail
              </label>
              <input
                className="form--control"
                type="email"
                name="email"
                id="email"
                placeholder="Tapez votre adresse e-mail"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="single-input">
              <label htmlFor="phone" className="label-title mb-3">
                Numéro de téléphone
              </label>
              <input
                className="form--control"
                type="tel"
                name="phone"
                id="phone"
                placeholder="Tapez votre numéro de téléphone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-flex-item">
            <div className="single-input">
              <label htmlFor="password" className="label-title mb-3">
                Créer un mot de passe
              </label>
              <input
                className="form--control"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Tapez votre mot de passe"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <div
                className="icon toggle-password"
                onClick={togglePasswordVisibility}
              >
                {!showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
              </div>
            </div>
          </div>

          <div className="input-flex-item">
            <div className="single-input">
              <label htmlFor="confirmePassword" className="label-title mb-3">
                Confirmer le mot de passe
              </label>
              <input
                className="form--control"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmePassword"
                id="confirmePassword"
                placeholder="Confirmez votre mot de passe"
                value={formData.confirmePassword}
                onChange={handleChange}
                required
              />
              <div
                className="icon toggle-password"
                onClick={toggleConfirmPasswordVisibility}
              >
                {!showConfirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </div>
            </div>
          </div>

          <button type="submit">S'inscrire</button>
        </form>
      </div>
      <Toaster />
    </div>
  );
}

export default Register;
