import React, { useEffect, useRef, useState } from "react";
import { BASE_URL_IMAGE } from "../../../constants/actions";
import { ProfileImg } from "../../../assets/images";
import UploadImage from "../../../helpers/uploadImages";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { UpdateClient } from "../../../actions/auth";

function EditProfile() {
  const client = useSelector((state) => state?.auth?.client);
  const [formData, setFormData] = useState(client);
  const [selectedImage, setSelectedImage] = useState(
    BASE_URL_IMAGE + client?.avatar
  );
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();


  useEffect(() => {
    setFormData(client);
    setSelectedImage(
      client?.avatar ? BASE_URL_IMAGE + client?.avatar : ProfileImg
    );
  }, [client]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormData({ ...formData, avatar: file });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updateData = async () => {
      try {
        let newPath = false;
        if (formData?.avatar && formData?.avatar !== client?.avatar) {
          newPath = await UploadImage(
            "avatar",
            { avatar: formData?.avatar },
            { avatar: client?.avatar },
            "avatars"
          );
        }
        const data = {
          ...formData,
          avatar: !newPath ? formData?.avatar : newPath,
        };
        dispatch(UpdateClient(data, client?.id)).then(res => {
          setSelectedImage(BASE_URL_IMAGE + res?.avatar);
        })
      } catch (err) {
        toast.error("Échec de la modification des informations d'utilisateur.");
        console.error(err);
      }
    };
    updateData();
  };

  return (
    <div className="dashboard-right-contents mt-4 mt-lg-0">
      <div className="dashboard-reservation">
        <div className="single-reservation bg-white base-padding">
          <h3 className="single-reservation-title">Modifier le profil</h3>
          <div className="custom--form dashboard-form">
            <form onSubmit={handleSubmit}>
              <div className="py-3">
                <div className="relative w-20 h-20 min-w-20 min-h-20 md:w-24 md:h-24 md:min-w-24 md:min-h-24 rounded-full bg-white">
                  <input
                    type="file"
                    id="img"
                    onChange={handleChangeAvatar}
                    className="sr-only"
                    ref={fileInputRef}
                  />
                  <label htmlFor="img">
                    <img
                      src={selectedImage}
                      alt="avatar"
                      className={`object-cover w-full h-full rounded-full cursor-pointer`}
                    />
                  </label>
                  <button
                    type="button"
                    className="absolute bottom-1.5 right-1.5 z-40 text-white bg-black w-6 h-6 min-w-6 min-h-6 rounded-full"
                    onClick={() => document.getElementById("img").click()}
                  >
                    <i className="bi bi-image-alt"></i>
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="space-y-1">
                  <label htmlFor="first_name">Prénom</label>
                  <input
                    className="w-full p-2 border outline-none focus:outline-none"
                    id="first_name"
                    type="text"
                    name="first_name"
                    placeholder="prénom"
                    value={formData?.first_name || ""}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="last_name">Nom</label>
                  <input
                    className="w-full p-2 border outline-none focus:outline-none"
                    id="last_name"
                    type="text"
                    name="last_name"
                    placeholder="nom"
                    value={formData?.last_name || ""}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="userEmail">Adresse e-mail</label>
                  <input
                    className="w-full p-2 border outline-none focus:outline-none"
                    id="userEmail"
                    type="email"
                    name="email"
                    placeholder="adresse e-mail"
                    value={formData?.email || ""}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className="space-y-1">
                  <label htmlFor="userPhone">Numéro de téléphone</label>
                  <input
                    className="w-full p-2 border outline-none focus:outline-none"
                    id="userPhone"
                    type="tel"
                    name="phone"
                    placeholder="numéro de téléphone"
                    value={formData?.phone || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 col-span-1 md:col-span-2">
                  <div className="space-y-1">
                    <label htmlFor="birthday">Date Naissance</label>
                    <input
                      className="w-full p-2 border outline-none focus:outline-none"
                      id="birthday"
                      type="date"
                      name="birthday"
                      placeholder="Date Naissance"
                      value={formData?.birthday || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="gender">Genre</label>
                    <select
                      onChange={handleChange}
                      name="gender"
                      id="gender"
                      className={`w-full p-2.5 border outline-none focus:outline-none`}
                      value={formData?.gender || ""}
                    >
                      <option value="">--genre--</option>
                      <option value="femme">Femme</option>
                      <option value="homme">Homme</option>
                    </select>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="code_postal">Code postal</label>
                    <input
                      className="w-full p-2 border outline-none focus:outline-none"
                      id="code_postal"
                      type="number"
                      name="code_postal"
                      placeholder="code postal"
                      value={formData?.code_postal || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="space-y-1 col-span-1 md:col-span-2">
                  <label htmlFor="adresse">Adresse</label>
                  <input
                    className="w-full p-2 border outline-none focus:outline-none"
                    id="adresse"
                    type="text"
                    name="adresse"
                    placeholder="code adresse"
                    value={formData?.adresse || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="btn-wrapper mt-4">
                <button type="submit" className="cmn-btn btn-bg-1">
                  Enregistrer les modifications
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster/>
    </div>
  );
}

export default EditProfile;
