import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-router-dom";
import { ProfileImg } from "../../../assets/images";
import { BASE_URL_IMAGE } from "../../../constants/actions";
import { useSelector } from "react-redux";

function Profile({ setSelectedOne }) {
  const client = useSelector((state) => state?.auth?.client);


  const handleSwitch = (selon) => {
    setSelectedOne(selon);
  };

  const formatTimeSince = (updatedAt) => {
    const now = new Date();
    const updatedDate = new Date(updatedAt);
    const diffInMilliseconds = now - updatedDate;
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);
  
    if (diffInDays < 30) {
      if (diffInDays === 0) {
        return `Dernière modification Aujourd'hui`;
      } else {
        return `Dernière modification il y a ${diffInDays} jours`;
      }
    } else if (diffInMonths < 12) {
      return `Dernière modification il y a ${diffInMonths} mois`;
    } else {
      const yearsPart =
        diffInYears > 0 ? `${diffInYears} an${diffInYears > 1 ? "s" : ""}` : "";
      const monthsPart =
        diffInMonths % 12 > 0 ? `${diffInMonths % 12} mois` : "";
      return `Dernière modification il y a ${yearsPart} ${monthsPart}`.trim();
    }
  };

  return (
    <div className="dashboard-right-contents mt-4 mt-lg-0">
      <div className="dashboard-reservation">
        <div className="single-reservation bg-white base-padding">
          <div className="single-reservation-flex mb-4">
            <div className="single-reservation-author">
              <div className="single-reservation-author-flex">
                <div className="single-reservation-author-thumb">
                  <img
                    src={
                      client?.avatar
                        ? BASE_URL_IMAGE + client?.avatar
                        : ProfileImg
                    }
                    alt="img"
                  />
                </div>
                <div className="single-reservation-author-contents">
                  <h5 className="single-reservation-author-contents-title">
                    {client?.first_name} {client?.last_name}
                  </h5>
                  <p className="single-reservation-author-contents-para">
                    Membre depuis {new Date(client?.created_at).getFullYear()}
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-wrapper" onClick={()=>handleSwitch("edit-profile")}>
              <button className="cmn-btn btn-border">
                Modifier le profil
              </button>
            </div>
          </div>
          <div className="single-reservation-item">
            <div className="single-reservation-contact">
              {client?.adresse && (
                <div className="single-reservation-contact-item">
                  <span className="single-reservation-contact-list">
                    <span>
                      <LocationOnIcon />
                    </span>
                    {client?.adresse}
                  </span>
                </div>
              )}
              {client?.email && (
                <div className="single-reservation-contact-item">
                  <Link
                    to={`mailto:${client?.email}`}
                    className="single-reservation-contact-list"
                  >
                    <span>
                      <EmailIcon />
                    </span>
                    {client?.email}
                  </Link>
                </div>
              )}
              {client?.phone && (
                <div className="single-reservation-contact-item">
                  <Link
                    to={`tel:${client?.phone}`}
                    className="single-reservation-contact-list"
                  >
                    <span>
                      <PhoneIcon />
                    </span>
                    {client?.phone}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="single-reservation bg-white base-padding">
          <div className="single-reservation-flex">
            <div className="single-reservation-author">
              <div className="single-reservation-author-flex">
                <div className="single-reservation-author-contents">
                  <h5 className="single-reservation-author-contents-title">
                    Mot de passe
                  </h5>
                  <p className="single-reservation-author-contents-para">
                    {client?.updated_at && formatTimeSince(client?.updated_at)}
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-wrapper" onClick={()=>handleSwitch("password")}>
              <button className="cmn-btn btn-border">
                Changer le mot de passe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
