import React from 'react';
import './style.scss';

function ContactUs() {
  return (
    <div className='contact-us-page'>
        
    </div>
  )
}

export default ContactUs