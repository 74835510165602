import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./style.scss";
import logo from "../../assets/img/skipp-logo1.jpg";
import { SignInEntreprise } from "../../components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL_IMAGE } from "../../constants/actions";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

function Entreprise() {
  const entreprise = useSelector((state) => state?.data?.entreprise);
  const galeries = useSelector((state) => state?.data?.galerie);
  const popupRef = useRef(null);
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const fonctionsArray = [
    {
      icon: <LibraryBooksIcon />,
      title: entreprise?.titleFonct1,
      description: entreprise?.descriptionFonct1,
    },
    {
      icon: <PlayCircleIcon />,
      title: entreprise?.titleFonct2,
      description: entreprise?.descriptionFonct2,
    },
    {
      icon: <SupportAgentIcon />,
      title: entreprise?.titleFonct3,
      description: entreprise?.descriptionFonct3,
    },
  ];

  const socialLinks = [
    { icon: "la-facebook", link: "#" },
    { icon: "la-twitter", link: "#" },
    { icon: "la-instagram", link: "#" },
    { icon: "la-youtube", link: "#" },
    { icon: "la-linkedin", link: "#" },
  ];

  const testimonials = [
    {
      content: entreprise?.avis1,
      image: BASE_URL_IMAGE + entreprise?.imageAvis1,
      title: entreprise?.titleAvis1,
      subtitle: entreprise?.subtitleAvis1,
    },
    {
      content: entreprise?.avis2,
      image: BASE_URL_IMAGE + entreprise?.imageAvis2,
      title: entreprise?.titleAvis2,
      subtitle: entreprise?.subtitleAvis2,
    },
    {
      content: entreprise?.avis3,
      image: BASE_URL_IMAGE + entreprise?.imageAvis3,
      title: entreprise?.titleAvis3,
      subtitle: entreprise?.subtitleAvis3,
    },
  ];

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [opclMenu, setOpClMenu] = useState(false);
  const handleOpenCloseMenu = (e) => {
    e.preventDefault();
    setOpClMenu(!opclMenu);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
      document.body.classList.remove("popup-open");
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    setOpClMenu(false);
  }, [location]);

  return (
    <>
      <div className="votre-entreprise">
        <div
          id="navbar"
          className={`navbar navbar-expand-lg justify-content-center align-items-center ${
            isNavbarFixed ? "navbar-fixed" : ""
          }`}
        >
          <div className="container">
            <button className="navbar-brand" onClick={() => navigate("/")}>
              <img src={logo} alt="logo" />
            </button>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={(e) => handleOpenCloseMenu(e)}
            >
              {opclMenu ? <CloseIcon /> : <MenuIcon />}
            </button>

            {screenWidth > 1200 ?
            (<div
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav nav">
                <li className="nav-item">
                  <a className="nav-link" href={"/entreprise#home"}>
                    Accueil
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={"/entreprise#about"}>
                    À propos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={"/entreprise#temoi"}>
                    Témoignages
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={"/entreprise#gallery"}>
                    Galerie
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={"/entreprise#fonct"}>
                    Fonctionnalités
                  </a>
                </li>
                {screenWidth < 992 && (
                  <li className="">
                    <button
                      onClick={togglePopup}
                      className="mx-auto transition-colors duration-300 ease-in-out group"
                      style={{
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid black",
                      }}
                    >
                      <span className="py-2 px-4 transition-colors duration-300 ease-in-out group-hover:text-white group-hover:bg-black">
                        Rejoindre maintenant
                      </span>
                    </button>
                  </li>
                )}
              </ul>
            </div>)
            :
            (
              opclMenu && <div
              className="header-v-mobile"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav nav">
                  <li className="nav-item">
                    <a className="nav-link" href={"/entreprise#home"}>
                      Accueil
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={"/entreprise#about"}>
                      À propos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={"/entreprise#temoi"}>
                      Témoignages
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={"/entreprise#gallery"}>
                      Galerie
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href={"/entreprise#fonct"}>
                      Fonctionnalités
                    </a>
                  </li>
                  {screenWidth < 992 && (
                    <li className="">
                      <button
                        onClick={togglePopup}
                        className="mx-auto transition-colors duration-300 ease-in-out group"
                        style={{
                          color: "black",
                          backgroundColor: "white",
                          border: "1px solid black",
                        }}
                      >
                        <span className="py-2 px-4 transition-colors duration-300 ease-in-out group-hover:text-white group-hover:bg-black">
                          Rejoindre maintenant
                        </span>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )
            }

            <ul className="button-navbar" onClick={togglePopup}>
              <li>
                <button className="button">Rejoindre maintenant</button>
              </li>
            </ul>
          </div>
        </div>
        {showPopup && (
          <div className="popup-overlay2">
            <div className="popup" ref={popupRef}>
              <div className="popup-content">
                <button className="close-popup" onClick={togglePopup}>
                  X
                </button>
                <SignInEntreprise
                  showPopup={showPopup}
                  setShowPopup={setShowPopup}
                />
              </div>
            </div>
          </div>
        )}
        <div id="home" className="intro">
          <div className="container">
            <div className="content">
              <div className="row align-items-center">
                <div className="col-md-12 col-sm-12 col-lg-7">
                  <div className="content-text">
                    <h1>{entreprise?.homeTitle}</h1>
                    <div
                      className="text-justify"
                      dangerouslySetInnerHTML={{
                        __html: entreprise?.homeDescription,
                      }}
                    />
                    <div className="flex items-center gap-3 sm:text-lg">
                      <button className="call-to-action" onClick={togglePopup}>
                        {entreprise?.homeButton1}
                      </button>
                      {/* <button
                        className="call-to-action"
                        onClick={() => navigate(entreprise?.homeLien2)}
                      >
                        {entreprise?.homeButton2}
                      </button> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md"></div> */}
              </div>
              <div className="row mb-10">
                <div className="col-md"></div>
                <div className="col-md-9">
                  <img
                    src={BASE_URL_IMAGE + entreprise?.homeImage}
                    alt="introimg"
                  />
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>

        <div id="about" className="about">
          <div className="container">
            <h2 className="title-land">{entreprise?.aboutTitle}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: entreprise?.aboutDescription }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="testemo" id="temoi">
          <h2 className="title-land">{entreprise?.temoiTitle}</h2>
          <>
            {screenWidth <= 768 ? (
              <div className="salon-list carousel-one">
                <Carousel>
                  {testimonials?.map((testimonial, index) => (
                    <Carousel.Item key={index}>
                      <figure className="snip1157" key={index}>
                        <blockquote>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: testimonial?.content,
                            }}
                          />
                          <div className="arrow"></div>
                        </blockquote>
                        <img
                          src={testimonial?.image}
                          alt={`Testimonial ${index + 1}`}
                        />
                        <div className="author">
                          <h5>
                            {testimonial?.title}{" "}
                            <span>{testimonial?.subtitle}</span>
                          </h5>
                        </div>
                      </figure>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ) : (
              <div className="testemo-body">
                {testimonials?.map((testimonial, index) => (
                  <figure className="snip1157" key={index}>
                    <blockquote>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: testimonial?.content,
                        }}
                      />
                      <div className="arrow"></div>
                    </blockquote>
                    <img
                      src={testimonial?.image}
                      alt={`Testimonial ${index + 1}`}
                    />
                    <div className="author">
                      <h5>
                        {testimonial?.title}{" "}
                        <span>{testimonial?.subtitle}</span>
                      </h5>
                    </div>
                  </figure>
                ))}
              </div>
            )}
          </>
        </div>

        <div id="gallery" className="gallery">
          <div className="container">
            <div className="row">
              <div className="col-md">
                <h2 className="title-land">{entreprise?.galleryTitle}</h2>
              </div>
              <div className="col-md">
                <div
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: entreprise?.galleryDescription,
                  }}
                />
              </div>
            </div>
            <Carousel>
              {galeries?.map((galerie) => (
                <Carousel.Item key={galerie?.id}>
                  <img
                    className="d-block w-100"
                    src={BASE_URL_IMAGE + galerie?.image}
                    alt="img"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>

        <section className="pat-100 pab-50" id="fonct">
          <div className="container">
            <h2 className="title-land" style={{ textAlign: "center" }}>
              {entreprise?.fonctTitle}
            </h2>
            <br />
            <br />

            <>
              {screenWidth <= 768 ? (
                <div className="salon-list carousel-one">
                  <Carousel>
                    {fonctionsArray?.map((item, index) => (
                      <Carousel.Item key={index}>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <div className="single-why-two bg-white single-why-two-border radius-10">
                            <div className="single-why-two-flex">
                              <div className="single-why-two-icon">
                                {item?.icon}
                              </div>
                              <div className="single-why-two-contents">
                                <h4 className="single-why-two-contents-title">
                                  <button> {item?.title} </button>
                                </h4>
                                <p
                                  className="single-why-two-contents-para mt-2"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="row g-4">
                  <div
                    className="col-xl-3 col-lg-4 col-md-6"
                    style={{ display: "none" }}
                  ></div>
                  {fonctionsArray?.map((item, index) => (
                    <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                      <div className="single-why-two bg-white single-why-two-border radius-10">
                        <div className="single-why-two-flex">
                          <div className="single-why-two-icon">
                            {item?.icon}
                          </div>
                          <div className="single-why-two-contents">
                            <h4 className="single-why-two-contents-title">
                              <button> {item?.title} </button>
                            </h4>
                            <p
                              className="single-why-two-contents-para mt-2"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          </div>
        </section>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md col-sm-6">
                <ul>
                  {socialLinks?.map((social, index) => (
                    <li key={index}>
                      <Link to={social.link}>
                        <i className={`la ${social.icon}`}></i>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md col-sm-6">
                <div className="cp">
                  <span> Copyright © Tous droits réservés </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="scroll-to-top">
        {isVisible && (
          <button onClick={scrollToTop} className="scroll-button">
            <ArrowUpwardIcon />
          </button>
        )}
      </div>
    </>
  );
}

export default Entreprise;
