import React, { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../actions/api";
import { toast } from "react-toastify";
import { Lg1 } from "../../assets/images";
import { FetchData } from "../../actions/data";
import { FAQS } from "../../constants/actions";
// import '../../assets/css/animate.css'

function Faqs({ title }) {
  const faqData = useSelector(state => state?.data?.faqs);
  const [activeIndex, setActiveIndex] = useState(null);
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleSubmit = async e =>{
    e.preventDefault();
    setLoading(true)
    try{
      await apiClient().post('/questions', formData);
      // await apiClient().post('/sendEmail', formData);
      toast.success('Le message et bien envoyé');
      setLoading(false);
      setFormData();
      dispatch(FetchData('/faqs', FAQS));
    }catch(err){
      console.error(err);
      setLoading(false)
    }
  }

  return (
    <section className="question-area pat-50 pab-50">
      <div className="container">
        <div className="section-title center-text">
          <h2 className="title">{title}</h2>
          <div className="section-title-line"> </div>
        </div>
        <div className="row g-4 mt-4">
          <div className="col-xl-8 col-lg-7">
            <div className="faq-wrapper">
              <div className="faq-contents">
                {faqData?.map((item, index) => (
                  <div
                    key={index}
                    className={`faq-item fadeInLeft ${
                      activeIndex === index ? "active" : ""
                    }`}
                    style={{
                      visibility: "visible",
                    }}
                    onClick={() => handleToggle(index)}
                  >
                    <h3 className="faq-title">{item?.question}<ArrowDropDownIcon /></h3>
                    <div className="faq-panel">
                      <p className="faq-para text-justify" dangerouslySetInnerHTML={{ __html: item?.reponse }}/>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="faq-question faq-question-border radius-10">
              {/* sticky-top */}
              <h3 className="faq-question-title"> Encore des questions? </h3>
              <div className="faq-question-form custom-form mat-20">
                <form onSubmit={handleSubmit}>
                  <div className="single-input">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form--control radius-10"
                      placeholder="Entrez votre nom"
                      value={formData?.name || ""}
                      onChange={e=>setFormData({...formData, [e.target?.name]: e.target.value})}
                      required={true}
                    />
                  </div>
                  <div className="single-input">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="form--control radius-10"
                      placeholder="Entrez votre email"
                      value={formData?.email || ""}
                      onChange={e=>setFormData({...formData, [e.target?.name]: e.target.value})}
                      required={true}
                    />
                  </div>
                  <div className="single-input">
                    <textarea
                      name="question"
                      id="question"
                      className="form--control form-message radius-10"
                      placeholder="Posez vos questions..."
                      value={formData?.question || ""}
                      onChange={e=>setFormData({...formData, [e.target?.name]: e.target.value})}
                      required={true}
                    ></textarea>
                  </div>
                  <button className="submit-btn w-full rounded-lg flex items-center justify-center" type="submit">
                    {loading ? (
                      <img src={Lg1} className="w-auto h-7 max-h-7 min-h-7" />
                    ) : ('Soumettre')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
