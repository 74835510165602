import React from "react";
import "./style.scss";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { EntetePage } from "../../components";
import {
  Salonimg1,
  Salonimg2,
  Salonimg3,
  Salonimg4,
} from "../../assets/images/topsalons";
import { Link } from "react-router-dom";

function GridListing() {
  const datasalons = [
    {
      name: "Salon de Beauté Chic",
      prixbarre: 450,
      prixnow: 350,
      services: ["Manucure", "Pédicure", "Coiffure", "Maquillage", "Massage"],
      ville: "Marrakech",
      picture: Salonimg1,
      rate: "4 (380)",
    },
    {
      name: "Salon de Coiffure Élégance",
      prixbarre: 400,
      prixnow: 300,
      services: [
        "Coupe",
        "Coloration",
        "Brushing",
        "Lissage",
        "Traitement capillaire",
      ],
      ville: "Casablanca",
      picture: Salonimg2,
      rate: "4.5 (220)",
    },
    {
      name: "Institut de Massage Zen",
      prixbarre: 500,
      prixnow: 400,
      services: [
        "Massage relaxant",
        "Massage thérapeutique",
        "Massage aux pierres chaudes",
        "Réflexologie",
        "Massage sportif",
      ],
      ville: "Fès",
      picture: Salonimg3,
      rate: "4.7 (40)",
    },
    {
      name: "Centre de Bien-être Harmonie",
      prixbarre: 600,
      prixnow: 450,
      services: [
        "Soins du visage",
        "Épilation",
        "Gommage corporel",
        "Enveloppement",
        "Hydrothérapie",
      ],
      ville: "Tanger",
      picture: Salonimg4,
      rate: "4.2 (69)",
    },
  ];
  return (
    <div>
      <EntetePage title={"Page Listing"} />
      <div className="package-grid-section pt-120 mb-120">
        <div className="container">
          <div className="row gy-5 gx-3">
            {datasalons.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="package-card">
                  <div className="package-card-img-wrap">
                    <Link to={"/detail-page"} className="card-img">
                      <img src={item.picture} alt="img" />
                    </Link>
                    <div className="batch">
                      <span className="date">
                        <StarIcon />
                        {item.rate}
                      </span>
                      <div className="location">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path d="M8.99939 0C5.40484 0 2.48047 2.92437 2.48047 6.51888C2.48047 10.9798 8.31426 17.5287 8.56264 17.8053C8.79594 18.0651 9.20326 18.0646 9.43613 17.8053C9.68451 17.5287 15.5183 10.9798 15.5183 6.51888C15.5182 2.92437 12.5939 0 8.99939 0ZM8.99939 9.79871C7.19088 9.79871 5.71959 8.32739 5.71959 6.51888C5.71959 4.71037 7.19091 3.23909 8.99939 3.23909C10.8079 3.23909 12.2791 4.71041 12.2791 6.51892C12.2791 8.32743 10.8079 9.79871 8.99939 9.79871Z"></path>
                        </svg>
                        <ul className="location-list">
                          <li>
                            <Link to={"#"}>{item.ville}</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="package-card-content">
                    <div className="card-content-top">
                      <h5>
                        <Link to={"#"}>{item.name}</Link>
                      </h5>
                      <div className="location-area2">
                        <ul className="location-list scrollTextAni">
                          {item.services.map((serv, servIndex) => (
                            <li key={servIndex}>
                              <Link to={"#"}>{serv}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="card-content-bottom">
                      <div className="price-area">
                        <h6>À partir de :</h6>
                        <span>
                          {item.prixnow}Dhs<del>{item.prixbarre}Dhs</del>
                        </span>
                        <p>PAR/PERS</p>
                      </div>
                      <Link
                        to={"#"}
                        className="primary-btn2 py-2 px-2 min-w-fit"
                      >
                        Réserver maintenant
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <nav className="inner-pagination-area">
                <ul className="pagination-list">
                  <li>
                    <button className="active" aria-current="page">
                      01
                    </button>
                  </li>
                  <li>
                    <button className="">02</button>
                  </li>
                  <li>
                    <ArrowForwardIosIcon />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridListing;
