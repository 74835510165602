import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { CreateDemande } from "../../actions/data";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function SignInEntreprise({ showPopup, setShowPopup }) {
  const [userData, setUserData] = useState();
  const [salonData, setSalonData] = useState();
  const [termCondition, setTermCondition] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangeUserData = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData({
      ...userData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleChangeSalonData = (e) => {
    const { name, value, type, checked } = e.target;
    setSalonData({
      ...salonData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!termCondition) {
      toast.error("Il faut accepter les conditions d'utilisation");
      return;
    }
  
    if (userData?.password !== userData?.confirmePassword) {
      toast.error("Les mots de passe ne sont pas compatibles !");
      return;
    }
  
    try {
      dispatch(CreateDemande(salonData, userData));
      setSalonData({});
      setUserData({});
      setTermCondition(false);
      toast.success(
        "Votre demande a bien été envoyée. Un e-mail vous sera envoyé une fois votre demande traitée.",
        {
          autoClose: 3700,
          onClose: () => {
            setShowPopup(false);
          }
        }
      );
    } catch (err) {
      toast.error("Une erreur est survenue lors de l'envoi de la demande");
    }
  };
  

  return (
    <div className="signinentreprise">
      <section className="l-area padding-top-100 padding-bottom-100">
        <div>
          <div className="login-wrapper bg-white">
            <div className="login-wrapper-flex">
              <div className="login-wrapper-contents login-padding">
                <form
                  className="custom-form text-sm md:text-base space-y-3"
                  onSubmit={handleSubmit}
                >
                  <div className="flex items-center gap-2">
                    <div className="w-full border"></div>
                    <div className="text-gray-500 min-w-fit">
                      Informations Institut
                    </div>
                    <div className="w-full border"></div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
                    <div className="space-y-1 col-span-1 md:col-span-2">
                      <label htmlFor="instituName">Nom de l’institut *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="instituName"
                        type="text"
                        name="name"
                        placeholder="Tapez le nom de l’institut"
                        value={salonData?.name || ""}
                        onChange={handleChangeSalonData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="institutEmail">Adresse e-mail *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="institutEmail"
                        type="email"
                        name="email"
                        placeholder="Adresse e-mail"
                        value={salonData?.email || ""}
                        onChange={handleChangeSalonData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="institutPhone">Numéro de téléphone</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="institutPhone"
                        type="tel"
                        name="phone_number"
                        placeholder="Téléphone"
                        value={salonData?.phone || ""}
                        onChange={handleChangeSalonData}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="institutCity">Ville *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="institutCity"
                        type="text"
                        name="city"
                        placeholder="Ville"
                        value={salonData?.city || ""}
                        onChange={handleChangeSalonData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="institutAddress">Adresse *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="institutAddress"
                        type="text"
                        name="address"
                        placeholder="Adresse"
                        value={salonData?.address || ""}
                        onChange={handleChangeSalonData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="institutLatitude">Lattitude</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="institutLatitude"
                        type="text"
                        name="latitude"
                        placeholder="Lattitude"
                        value={salonData?.latitude || ""}
                        onChange={handleChangeSalonData}
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="institutLongitude">Longitude</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="institutLongitude"
                        type="text"
                        name="longitude"
                        placeholder="Longitude"
                        value={salonData?.longitude || ""}
                        onChange={handleChangeSalonData}
                      />
                    </div>
                    <div className="space-y-1 col-span-1 md:col-span-2">
                      <label htmlFor="institutDescription">Description</label>
                      <textarea
                        className="w-full p-2 border outline-none focus:outline-none min-h-fit h-fit max-h-56"
                        id="institutDescription"
                        name="description"
                        placeholder="Description"
                        value={salonData?.description || ""}
                        onChange={handleChangeSalonData}
                      />
                    </div> */}
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="w-full border"></div>
                    <div className="text-gray-500 min-w-fit">
                      Informations Propriétaire
                    </div>
                    <div className="w-full border"></div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
                    <div className="space-y-1">
                      <label htmlFor="first_name">Prénom *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="first_name"
                        type="text"
                        name="first_name"
                        placeholder="Tapez votre prénom"
                        value={userData?.first_name || ""}
                        onChange={handleChangeUserData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="last_name">Nom *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="last_name"
                        type="text"
                        name="last_name"
                        placeholder="Tapez votre nom"
                        value={userData?.last_name || ""}
                        onChange={handleChangeUserData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="userEmail">Adresse e-mail *</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="userEmail"
                        type="email"
                        name="email"
                        placeholder="Tapez votre adresse e-mail"
                        value={userData?.email || ""}
                        onChange={handleChangeUserData}
                        required={true}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="userPhone">Numéro de téléphone</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="userPhone"
                        type="tel"
                        name="phone"
                        placeholder="Tapez votre numéro de téléphone"
                        value={userData?.phone || ""}
                        onChange={handleChangeUserData}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="birthday">Date Naissance</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="birthday"
                        type="date"
                        name="birthday"
                        placeholder="Date Naissance"
                        value={userData?.birthday || ""}
                        onChange={handleChangeUserData}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="code_postal">Code postal</label>
                      <input
                        className="w-full p-2 border outline-none focus:outline-none"
                        id="code_postal"
                        type="number"
                        name="code_postal"
                        placeholder="Tapez votre code postal"
                        value={userData?.code_postal || ""}
                        onChange={handleChangeUserData}
                        autocomplete="off"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="password">Créer un mot de passe *</label>
                      <div className="flex items-center gap-2 border p-2">
                        <input
                          className="w-full outline-none focus:outline-none"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Créez un mot de passe"
                          value={userData?.password || ""}
                          onChange={handleChangeUserData}
                          required={true}
                          autocomplete="off"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          {!showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="confirmePassword">
                        Confirmer le mot de passe *
                      </label>
                      <div className="flex items-center gap-2 border p-2">
                        <input
                          className="w-full outline-none focus:outline-none"
                          id="confirmePassword"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmePassword"
                          placeholder="Confirmez votre mot de passe"
                          value={userData?.confirmePassword || ""}
                          onChange={handleChangeUserData}
                          required={true}
                          autocomplete="off"
                        />
                        <button
                          type="button"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {!showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="w-full border"></div>
                    <div className="text-gray-500 min-w-fit">
                    Conditions Générales et Politique de Confidentialité
                    </div>
                    <div className="w-full border"></div>
                  </div>
                  <div className="grid grid-cols-1 gap-y-2">
                    <div className="flex items-center gap-3">
                      <input
                        className="check-input"
                        type="checkbox"
                        id="check15"
                        name="acceptTerms"
                        checked={termCondition}
                        onChange={(e) => setTermCondition(!termCondition)}
                        required={true}
                        autocomplete="off"
                      />
                      <label
                        className="text-justify cursor-pointer"
                        htmlFor="check15"
                      >
                        En créant un compte, vous acceptez les {"  "}
                        <button className="color-one" onClick={()=>window.open("/conditions", "_blank")}>
                          conditions d'utilisation
                        </button>
                        {"  "}et les{"  "}
                        <button className="color-one" onClick={()=>window.open("/conditions", "_blank")}>
                          conditions générales
                        </button>
                        , ainsi que la{"  "}
                        <button className="color-one" onClick={()=>window.open("/politique", "_blank")}>
                          politique de confidentialité
                        </button>
                        .
                      </label>
                    </div>
                    <button className="submit-btn" type="submit">
                      Inscrivez-vous maintenant
                    </button>
                    <span className="color-light">
                      Vous avez déjà un compte? {"  "}
                      <a className="color-one" href="https://admin.skipp.ma">
                        Connectez-vous
                      </a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster />
    </div>
  );
}

export default SignInEntreprise;
