

export const BASE_URL = "https://gestion.skipp.ma/public/api";
export const BASE_URL_PROD = "";
export const BASE_URL_IMAGE = "https://gestion.skipp.ma/public/";

export const TOGGLE_LOADING = false;

export const LOGIN_CLIENT = "LOGIN_CLIENT";
export const LOGOUT_CLIENT = "LOGOUT_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

// export const LOGIN_SALON = "LOGIN_SALON";
// export const LOGOUT_SALON = "LOGOUT_SALON";


export const DELETE_ALL_DATA = "DELETE_ALL_DATA";

export const CLIENT = "CLIENT";
export const BOOKINGS = "BOOKINGS";
export const APROPOS = "APROPOS";
export const BLOGS = "BLOGS";
export const SERVICES = "SERVICES";
export const SALONS = "SALONS";
export const AVAILABILITIES = "AVAILABILITIES";
export const SUGGESTIONS = "SUGGESTIONS";
export const CONDITIONS = "CONDITIONS";
export const POLITIQUES = "POLITIQUES";
export const RGBD = "RGBD";
export const FAQS = "FAQS";
export const REASONS = "REASONS";
export const ACCUEIL = "ACCUEIL";
export const ENTREPRISE = "ENTREPRISE";
export const GALERIE = "GALERIE";
export const FEEDBACKS = "FEEDBACKS";
export const FOOTER = "FOOTER";


export const ADD_SALON = "ADD_SALON";
export const ADD_SERVICES = "ADD_SERVICES";
export const ADD_AVAILABILITY = "ADD_AVAILABILITY";
export const INIT_BOOKING = "INIT_BOOKING";









