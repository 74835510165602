import React, { useEffect, useState } from "react";
import { Star, StarHalf, StarOff, User, Users2 } from "lucide-react";

const EmployeeRatings = ({ salon }) => {
  const [selectedEmployee, setSelectedEmployee] = useState();
  
  useEffect(() => {
    const emp = salon?.employees?.find(emp => emp?.reviews?.length > 0)?.id;
    setSelectedEmployee(emp);
  }, [salon]);

  const renderStars = (averageRating) => {
    const roundedRating = Math.round(averageRating * 2) / 2;
    const filledStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 !== 0;
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<Star key={i} className="w-5 h-5 text-yellow-400" />);
    }

    if (hasHalfStar) {
      stars.push(<StarHalf key={stars.length} className="w-5 h-5 text-yellow-400" />);
    }

    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<StarOff key={stars.length} className="w-5 h-5 text-gray-300" />);
    }

    return stars;
  };

  const getAverageRating = (reviews) => {
    if (reviews?.length === 0) return 0;
    const totalRating = reviews?.reduce((sum, review) => sum + parseFloat(review.rating), 0);
    return totalRating / reviews?.length;
  };

  const hasEmployeesWithReviews = salon?.employees?.some(emp => emp?.reviews?.length > 0);

  if (!hasEmployeesWithReviews) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <Users2 className="w-16 h-16 text-gray-400 mb-4" />
        <h3 className="text-xl font-medium text-gray-700 mb-2">Aucun avis disponible</h3>
        <p className="text-gray-500">Soyez le premier à laisser un avis sur nos employés</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="flex flex-col sm:flex-row">
        {/* Employee Tabs */}
        <div className="w-full sm:w-64 border-b sm:border-b-0 sm:border-r border-gray-200">
          <div className="flex sm:flex-col overflow-x-auto sm:overflow-y-auto max-h-64">
            {salon?.employees?.map((employee) => {
              if (!employee?.reviews?.length) return null;
              
              return (
                <button
                  key={employee?.id}
                  onClick={() => setSelectedEmployee(employee?.id)}
                  className={`flex items-center p-4 w-full hover:bg-gray-50 transition-colors
                    ${selectedEmployee === employee?.id ? 'bg-gray-100' : ''}
                    ${selectedEmployee === employee?.id ? 'border-b-2 sm:border-b-0 sm:border-r-2 border-gray-800' : ''}
                  `}
                >
                  {employee?.avatar ? (
                    <img
                      src={employee?.avatar}
                      alt={employee?.first_name}
                      className="w-10 h-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <User className="w-6 h-6 text-gray-500" />
                    </div>
                  )}
                  <div className="ml-3 text-left">
                    <div className="font-medium text-gray-900">
                      {employee?.first_name} {employee?.last_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {employee?.reviews?.length} avis
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>

        {/* Employee Details */}
        {selectedEmployee && (
          <div className="flex-1 p-6">
            <div className="flex items-center space-x-2 mb-6">
              <div className="flex">
                {renderStars(getAverageRating(salon?.employees?.find(emp => emp?.id === selectedEmployee)?.reviews))}
              </div>
              <span className="text-gray-500">
                ({salon?.employees?.find(emp => emp?.id === selectedEmployee)?.reviews?.length} avis)
              </span>
            </div>

            <div className="space-y-4">
              <h3 className="font-medium text-gray-900">Prestations</h3>
              <div className="flex flex-wrap gap-2">
                {salon?.employees?.find(emp => emp?.id === selectedEmployee)?.services?.map(
                  (service, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm"
                    >
                      {service?.name}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeRatings;