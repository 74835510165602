import React from "react";
import PlaceIcon from "@mui/icons-material/Place";
import StarIcon from "@mui/icons-material/Star";
import "./style.scss";
import { BASE_URL_IMAGE, INIT_BOOKING } from "../../constants/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MapPin, Star } from "lucide-react";

function SalonItem({
  id,
  imagesalon,
  average_rating,
  reservationsLength,
  namesalon,
  adresse,
  price,
  services,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleNav = () => {
    dispatch({ type: INIT_BOOKING });
    navigate(`/detail-page`, { state: { id: id, prevPath: location?.pathname }})
  }

  return (
    <div className="bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow overflow-hidden">
      <div 
        className="h-48 bg-cover bg-center bg-no-repeat" 
        style={{ backgroundImage: `url(${BASE_URL_IMAGE + imagesalon})` }}
      >
        <div className="p-4 flex justify-between">
          <div className="bg-white/90 backdrop-blur-sm px-3 py-1.5 rounded-full flex items-center gap-1">
            <Star className="w-4 h-4 text-yellow-500 fill-yellow-500" />
            <span className="text-sm font-medium">{average_rating}</span>
            <span className="text-xs text-gray-600">({reservationsLength})</span>
          </div>
        </div>
      </div>
      
      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{namesalon}</h3>
        
        <div className="flex items-start gap-2 mb-4">
          <MapPin className="w-4 h-4 text-gray-500 mt-1 flex-shrink-0" />
          <span className="text-sm text-gray-600 line-clamp-2">{adresse}</span>
        </div>
        
        {services && (
          <div className="text-sm text-gray-500 mb-4 line-clamp-1">
            {services}
          </div>
        )}
        
        <div className="flex items-center justify-between mt-auto">
          <div>
            <div className="text-sm text-gray-600">à partir de</div>
            <div className="text-xl font-bold text-gray-900">{price} Dhs</div>
          </div>
          
          <button
            onClick={handleNav}
            className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg transition-colors"
          >
            Réserver maintenant
          </button>
        </div>
      </div>
    </div>
  );
}

export default SalonItem;
