import {  ACCUEIL, APROPOS, AVAILABILITIES, BLOGS, BOOKINGS, CONDITIONS, DELETE_ALL_DATA, ENTREPRISE, FEEDBACKS, FOOTER, GALERIE, POLITIQUES, FAQS, REASONS, RGBD, SALONS, SERVICES, SUGGESTIONS } from "../constants/actions";

const initialState = {};

const actionTypeToStateKey = {
  [BOOKINGS]: 'bookings',
  [APROPOS]: 'apropos',
  [BLOGS]: 'blogs',
  [SERVICES]: 'services',
  [SALONS]: 'salons',
  [AVAILABILITIES]: 'availabilities',
  [SUGGESTIONS]: 'suggestions',
  [CONDITIONS]: 'conditions',
  [POLITIQUES]: 'politiques',
  [RGBD]: 'rgbd',
  [FAQS]: 'faqs',
  [REASONS]: 'reasons',
  [ENTREPRISE]: 'entreprise',
  [GALERIE]: 'galerie',
  [FEEDBACKS]: 'feedbacks',
  [ACCUEIL]: 'accueil',
  [FOOTER]: 'footer',
};

const data = (state = initialState, action) => {
  const { type, payload } = action;
  const stateKey = actionTypeToStateKey[type];
  
  if (stateKey) {
    return { ...state, [stateKey]: payload };
  }else if(type === DELETE_ALL_DATA){
      return {
        ...state,
        bookings: {}
      };
  }else{
    return state;
  }
};

export default data;
