import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import "./style.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADD_AVAILABILITY, ADD_SALON, ADD_SERVICES } from "../../constants/actions";
import FormatDuration from '../../helpers/FormatDuration'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function ServicesTabsComp({ services, salon }) {
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const reservation = useSelector((state) => state?.reservation);
  const dispatch = useDispatch();

  const ToggleServices = (service) => {
    const serviceExists = reservation?.services?.includes(service);

    let updatedServices;
    if (serviceExists) {
      updatedServices = reservation?.services?.filter(
        (ser) => ser?.id !== service?.id
      );
    } else {
      updatedServices = [...(reservation?.services || []), service];
    }
    if(reservation?.salon?.id !== salon?.id){
      dispatch({ type: ADD_SALON, payload: salon });
      dispatch({ type: ADD_SERVICES, payload: [service] });

      dispatch({
        type: ADD_AVAILABILITY,
        payload: {
          date: null,
          start_time: null,
          end_time: null,
          employee_id: null,
        },
      });
    }else{
      dispatch({ type: ADD_SERVICES, payload: updatedServices });
    }
  };

  const BeautySalonPlanItem = ({ service }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="item-beauty-salon-plan">
        <div
          className="beauty-salon-plan-title"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="beauty-salon-plan-label">{service?.name}</span>
          <div>
            <span className="beauty-salon-plan-day">
              à partir de {service?.pivot?.price} Dhs
            </span>
            {!isOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </div>
        </div>
        {isOpen && (
          <div className="beauty-salon-plan-description">
            <div className="all-details">
              <Typography className="flex flex-col gap-2">
                <span>{FormatDuration(service?.pivot?.duration)}</span>
                <span>{service?.pivot?.description}</span>
              </Typography>
              <button
                className={`select-service ${
                  reservation?.services?.includes(service) &&
                  salon?.id === reservation?.salon?.id
                    ? "selected"
                    : ""
                }`}
                onClick={() => ToggleServices(service)}
              >
                {reservation?.services?.includes(service) && salon?.id === reservation?.salon?.id
                  ? "Sélectionné"
                  : "Sélectionner"}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const groupedServices = services?.reduce((acc, service) => {
    const { categorie } = service;
    if (!acc[categorie]) acc[categorie] = [];
    acc[categorie].push(service);
    return acc;
  }, {});

  const categories = Object.keys(groupedServices || []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        height: "auto",
        "& button": {
          color: "black",
          fontSize: "0.75rem",
          fontFamily: "CustomFont, sans-serif",
        },
        "& button:hover": { backgroundColor: "gray", color: "white" },
        "& button:focus": {
          backgroundColor: "gray",
          color: "white",
          borderColor: "black",
        },
        "& button.Mui-selected": {
          backgroundColor: "#dcdcdc",
          color: "black",
          fontSize: "0.8rem",
          fontWeight: "bold",
        },
        "& span.css-10d9dml-MuiTabs-indicator": { backgroundColor: "black" },
        "& .css-1aquho2-MuiTabs-indicator": { backgroundColor: "black" },
      }}
    >
      <Tabs
        orientation={isSmallScreen ? "horizontal" : "vertical"}
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
        aria-label="Services Categories Tabs"
        sx={{
          borderBottom: isSmallScreen ? 1 : 0,
          borderRight: isSmallScreen ? 0 : 1,
          borderColor: "divider",
          overflowY: !isSmallScreen ? "auto" : "hidden",
          maxHeight: !isSmallScreen ? "319px" : "auto",
        }}
      >
        {categories?.map((category, index) => (
          <Tab key={category} label={category} {...a11yProps(index)} />
        ))}
      </Tabs>

      {categories?.map((category, index) => (
        <TabPanel key={category} value={value} index={index}>
          <div
            className="beauty-salon-plan-content"
            style={{
              maxHeight: "319px",
              overflowY: "auto",
            }}
          >
            {groupedServices[category]?.map((service) => (
              <BeautySalonPlanItem
                key={service.id}
                service={service}
                isSelected={reservation?.services?.includes(service.id)}
              />
            ))}
          </div>
        </TabPanel>
      ))}
    </Box>
  );
}
