import React from "react";

const useCarousel = (items, interval = 5000) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = React.useState(true);
  
    React.useEffect(() => {
      if (!isAutoPlaying) return;
      
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % items.length);
      }, interval);
  
      return () => clearInterval(timer);
    }, [isAutoPlaying, items.length, interval]);
  
    const next = () => {
      setCurrentIndex((prev) => (prev + 1) % items.length);
      setIsAutoPlaying(false);
    };
  
    const prev = () => {
      setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
      setIsAutoPlaying(false);
    };
  
    return { currentIndex, next, prev };
  };

export default useCarousel;