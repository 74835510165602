
const GetBookingStatus = (date, start_time, end_time, defaultStatus = 1) => {
    const now = new Date();
    const bookingDate = new Date(`${date}T${start_time}`);
    const bookingEndDate = new Date(`${date}T${end_time}`);

    if(!defaultStatus){
        return { type: "Annulée", style: "cancelled" }
    }else if (now < bookingDate) {
        return { type: "En Attente", style: "pending" }
    } else if (now >= bookingDate && now <= bookingEndDate) {
        return { type: "Acceptée", style: "accepted" }
    } else if (now > bookingEndDate) {
        return { type: "Terminé", style: "completed" }
    }else{
        return;
    }
}

export default GetBookingStatus;
