import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import SearchIcon from "@mui/icons-material/Search";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { fr } from "date-fns/locale";

function SearchPart({ props }) {
  const [showLocationOptions, setShowLocationOptions] = useState(false);
  const locationDropdownRef = useRef(null);

  const [showCheckOutOptions, setShowCheckOutOptions] = useState(false);
  const checkOutDropdownRef = useRef(null);

  const [showPersonOptions, setShowPersonOptions] = useState(false);
  const personDropdownRef = useRef(null);

  const handleClickOutside = (event, ref, setShowOptions) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (event) =>
      handleClickOutside(event, locationDropdownRef, setShowLocationOptions)
    );
    document.addEventListener("mousedown", (event) =>
      handleClickOutside(event, checkOutDropdownRef, setShowCheckOutOptions)
    );
    document.addEventListener("mousedown", (event) =>
      handleClickOutside(event, personDropdownRef, setShowPersonOptions)
    );

    return () => {
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, locationDropdownRef, setShowLocationOptions)
      );
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, checkOutDropdownRef, setShowCheckOutOptions)
      );
      document.removeEventListener("mousedown", (event) =>
        handleClickOutside(event, personDropdownRef, setShowPersonOptions)
      );
    };
  }, []);

  const handleDateSelect = (date) => {
    props?.setCheckOutDate(date);
    setShowCheckOutOptions(false);
  };

  const formattedDate = props?.checkOutDate
    ? format(props?.checkOutDate, "PP", { locale: fr })
    : "Please pick a day.";

  const handleDayPickerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="banner-location banner-location-one">
      <div className="banner-location-flex">
        <div className="banner-location-single">
          <div className="banner-location-single-flex">
            <div className="banner-location-single-icon">
              <SearchIcon />
            </div>
            <div className="banner-location-single-contents">
              <div
                className={`nice-select js-select select-style-two ${
                  showPersonOptions ? "open" : ""
                }`}
                ref={personDropdownRef}
                tabIndex="0"
                onClick={() => setShowPersonOptions(!showPersonOptions)}
              >
                <span className="current">
                  {props?.person?.name
                    ? props?.person?.name
                    : "Toutes les prestations"}
                </span>
                {showPersonOptions && (
                  <ul className="list">
                    <li
                      className={`option ${
                        props?.person === "" ? "selected" : ""
                      }`}
                      onClick={() => props?.setPerson("")}
                    >
                      Toutes les prestations
                    </li>
                    {props?.personOptions.map((option, index) => (
                      <li
                        key={index}
                        data-value={index}
                        className={`option ${
                          props?.person === option ? "selected" : ""
                        }`}
                        onClick={() => props?.setPerson(option)}
                      >
                        {option?.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="banner-location-single">
          <div className="banner-location-single-flex">
            <div className="banner-location-single-icon">
              <PlaceIcon />
            </div>
            <div className="banner-location-single-contents">
              <div
                className={`nice-select js-select select-style-two ${
                  showLocationOptions ? "open" : ""
                }`}
                ref={locationDropdownRef}
                tabIndex="0"
                onClick={() => setShowLocationOptions(!showLocationOptions)}
              >
                <span className="current">
                  {props?.location === ""
                    ? "toutes les villes"
                    : props?.location}
                </span>
                {showLocationOptions && (
                  <ul className="list">
                    <li
                      className={`option capitalize ${
                        props?.location === "" ? "selected" : ""
                      }`}
                      onClick={() => props?.setLocation("")}
                    >
                      toutes les villes
                    </li>
                    {props?.locationOptions.map((option, index) => (
                      <li
                        key={index}
                        className={`option capitalize ${
                          props?.location === option ? "selected" : ""
                        }`}
                        onClick={() => props?.setLocation(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="banner-location-single">
          <div className="banner-location-single-flex">
            <div className="banner-location-single-icon">
              <CalendarMonthIcon />
            </div>
            <div className="banner-location-single-contents">
              <div
                className={`nice-select js-select select-style-two ${
                  showCheckOutOptions ? "open" : ""
                }`}
                ref={checkOutDropdownRef}
                tabIndex="0"
                onClick={() => setShowCheckOutOptions(!showCheckOutOptions)}
              >
                <span className="current">
                  {props?.checkOutDate ? formattedDate : "Choisir une date"}{" "}
                  {/* Placeholder inside input */}
                </span>
                {showCheckOutOptions && (
                  <ul
                    className="list checkoutdate"
                    onClick={handleDayPickerClick}
                  >
                    <DayPicker
                      mode="single"
                      selected={props?.checkOutDate}
                      onSelect={handleDateSelect}
                      fromDate={new Date()}
                      locale={fr}
                    />
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="banner-location-single-search">
          <div
            className="search-suggestions-wrapper"
            onClick={() => props?.handleSearch(1)}
          >
            <div className="search-click-icon">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchPart;
