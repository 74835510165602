import React, { useState, useEffect } from "react";
import { Star, BadgeCheck, ChevronLeft, ChevronRight, MessageSquare } from "lucide-react";
import FormatRelativeTime from "../../helpers/FormatRelativeTime";

const ReviewList = ({ reviews }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 4;

  useEffect(() => {
    setCurrentPage(1);
  }, [reviews]);

  const renderStars = (rating) => {
    return (
      <div className="flex gap-1">
        {[...Array(5)].map((_, index) => (
          <Star
            key={index}
            className={`w-5 h-5 ${
              index < rating
                ? "text-yellow-400 fill-yellow-400"
                : "text-gray-300"
            }`}
          />
        ))}
      </div>
    );
  };

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  if (reviews?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <MessageSquare className="w-16 h-16 text-gray-300 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Aucun avis disponible
        </h3>
        <p className="text-gray-500 text-center">
          Soyez le premier à partager votre expérience avec ce salon
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {currentReviews?.map((review) => (
          <div
            key={review?.id}
            className="bg-white rounded-lg p-6 shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
          >
            <div className="space-y-4">
              {/* Rating and Comment Section */}
              <div className="space-y-3">
                {renderStars(review?.rating)}
                <p className="text-gray-700 leading-relaxed">
                  {review?.comment}
                </p>
              </div>

              {/* Service Provider Info */}
              <div className="text-sm text-gray-600">
                Réalisé par{" "}
                <span className="font-medium text-blue-600">
                  {review?.employeeInfo?.first_name}{" "}
                  {review?.employeeInfo?.last_name}
                </span>
              </div>

              {/* Client Info and Date */}
              <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                <div className="flex items-center gap-2">
                  <span className="font-medium text-gray-900">
                    {review?.clientName}
                  </span>
                  <BadgeCheck className="w-5 h-5 text-blue-500" />
                </div>
                <span className="text-sm text-gray-500">
                  {FormatRelativeTime(review?.updated_at)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="flex items-center justify-center gap-4 pt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`p-2 rounded-full transition-colors ${
              currentPage === 1
                ? "text-gray-300 cursor-not-allowed"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            <ChevronLeft className="w-6 h-6" />
          </button>

          <span className="text-sm font-medium text-gray-700">
            Page {currentPage} sur {totalPages}
          </span>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`p-2 rounded-full transition-colors ${
              currentPage === totalPages
                ? "text-gray-300 cursor-not-allowed"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewList;