import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import "./style.scss";
import { useSelector } from "react-redux";
import { Skipp1 } from "../../assets/images";

function Footer() {
  const navigate = useNavigate();
  const footer = useSelector(state => state?.data?.footer);

  const footerLinks = [
    { to: "/", label: "Accueil" },
    { to: "/info-apropos", label: "À propos" },
    { to: "/sidelisting-page", label: "Liste des salons" },
    { to: "/blog", label: "Blog" },
    { to: "/entreprise", label: "Pour votre entreprise" },
    { to: "/conditions", label: "Conditions d'utilisation" },
    { to: "/politique", label: "Politique de confidentialité" },
    { to: "/rgbd", label: "RGPD" },
    { to: "/contact", label: "Contact" },
  ];

  const socialLinks = [
    { to: "#", icon: <FacebookIcon /> },
    { to: "#", icon: <XIcon /> },
    { to: "#", icon: <InstagramIcon /> },
    { to: "#", icon: <YouTubeIcon /> }
  ];

  return (
    <footer className="footer-area footer-area-two footer-bg-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-wrapper">
              <div className="footer-wrapper-flex">
                <div className="footer-wrapper-single">
                  <div className="footer-widget widget">
                    <div className="footer-widget-contents">
                      <div className="footer-widget-logo">
                        <img
                          style={{ maxWidth: "120px" }}
                          src={Skipp1}
                          alt="logo"
                          onClick={() => navigate("/")}
                        />
                      </div>
                      <div className="footer-widget-inner mt-4">
                        <p className="footer-widget-contents-para" dangerouslySetInnerHTML={{ __html: footer?.descriptionLogo }}/>
                        <div className="copyright-contents-items mt-5">
                          <div className="copyright-contents-single">
                            <div className="copyright-contents-single-flex">
                              <div className="copyright-contents-single-icon">
                                <LocalPhoneIcon />
                              </div>
                              <div className="copyright-contents-single-details">
                                <span className="copyright-contents-single-details-title">
                                  {footer?.titlePhone}
                                </span>
                                <Link
                                  to="tel:3104372766"
                                  className="copyright-contents-single-details-link"
                                >
                                  {footer?.phone}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="copyright-contents-single">
                            <div className="copyright-contents-single-flex">
                              <div className="copyright-contents-single-icon">
                                <EmailIcon />
                              </div>
                              <div className="copyright-contents-single-details">
                                <span className="copyright-contents-single-details-title">
                                  {footer?.titleEmail}
                                </span>
                                <Link
                                  to="mailto:unreal@example.com"
                                  className="footer-widget-nav-list-link"
                                >
                                  {footer?.email}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-wrapper-single">
                  <div className="footer-widget widget">
                    <h3 className="footer-widget-title text-white">
                      {footer?.titleReduction}
                    </h3>
                    <div className="footer-widget-inner mt-4">
                      <p className="footer-widget-para" dangerouslySetInnerHTML={{ __html: footer?.descriptionReduction }}/>
                      <div className="footer-widget-form mt-5">
                        <form action="#">
                          <div className="footer-widget-form-single">
                            <input
                              className="footer-widget-form-control"
                              type="text"
                              placeholder="Entrez votre e-mail"
                            />
                            <button type="submit"> Soumettre </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-navbar">
        <div className="container">
          <div className="footer-widget widget">
            <div className="footer-widget-nav">
              <ul className="footer-widget-nav-list list-style-none">
                {footerLinks?.map((link, index) => (
                  <li key={index} className="footer-widget-nav-list-item">
                    <button
                      onClick={() => navigate(link?.to)}
                      className="footer-widget-nav-list-link"
                    >
                      {link?.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area footer-padding">
        <div className="container">
          <div className="copyright-contents">
            <div className="copyright-contents-flex">
              <div className="footer-widget-social">
                <ul className="footer-widget-social-list list-style-none justify-content-center">
                  {socialLinks?.map((link, index) => (
                    <li key={index} className="footer-widget-social-list-item">
                      <button
                        className="footer-widget-social-list-link"
                        onClick={()=>navigate(link?.to)}
                      >
                        {link?.icon}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <span className="copyright-contents-main">
                © 2024, Tous droits réservés par <Link to="/">Skipp</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
