import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from '@mui/icons-material/StarBorder';

const ReviewFilterTwo = ({ selectedRating, setSelectedRating }) => {
  const handleRatingChange = (value) => {
    if (selectedRating === value) {
      setSelectedRating("All");
    } else {
      setSelectedRating(value);
    }
  };

  const renderCheckbox = (value, filledStars) => (
    <FormControlLabel
      control={
        <Checkbox
          className="check-one"
          checked={selectedRating === value}
          onChange={() => handleRatingChange(value)}
        />
      }
      label={
        <div>
          {[...Array(filledStars)].map((_, i) => (
            <StarIcon className="text-yellow-400 filled-one" key={i} />
          ))}
          {[...Array(5 - filledStars)].map((_, i) => (
            <StarBorderIcon className="text-black not-filled-one" key={i + filledStars} />
          ))}
        </div>
      }
      key={value}
    />
  );

  return (
    <div className="filter">
      <FormGroup>
        {renderCheckbox(5, 5)}
        {renderCheckbox(4, 4)}
        {renderCheckbox(3, 3)}
        {renderCheckbox(2, 2)}
        {renderCheckbox(1, 1)}
      </FormGroup>
    </div>
  );
};

export default ReviewFilterTwo;