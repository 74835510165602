import { LOGIN_CLIENT, LOGOUT_CLIENT, UPDATE_CLIENT } from "../constants/actions";

const initialState = {
  isClientAuth: false,
  client: null,
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === LOGIN_CLIENT) {
    return { ...state, isClientAuth: true, client: payload };
  }
  else if (type === LOGOUT_CLIENT) {
    return { ...state, isClientAuth: false, client: null };
  }
  else if (type === UPDATE_CLIENT) {
    return { ...state, client: payload };
  }
  else {
    return state;
  }
};

export default auth;
