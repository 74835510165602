import { toast } from "react-toastify";
import {
  DELETE_ALL_DATA,
  LOGIN_CLIENT,
  LOGOUT_CLIENT,
  UPDATE_CLIENT,
} from "../constants/actions";
import { apiClient } from "./api";

export const loginClient = (data, rememberMe = false) => {
  return async (dispatch) => {
    return apiClient()
    .post(`/connexion`, data)
    .then((response) => {
      if (!response?.data?.data?.status) {
        return { success: false, message: response?.data?.data?.message}
      } else {
        dispatch({ type: LOGIN_CLIENT, payload: response?.data?.data });
        return { success: true, message: response.data?.data?.message }; // Return the response data
      
      }
    })
    .catch ((error) => {
      const errorMessage = error.response?.data?.message || "login Failed!";
          toast.error(errorMessage); // Show error toast
          return { success: false, message: errorMessage };
    })
  };
}

  // export const registerClient = (data, rememberMe = false) => async (dispatch) => {
  //     apiClient().post(`/register`, data).then((response) => {
  //       if (!response.data?.data?.status) {
  //         toast.error(response.data?.data?.message);
  //       } else {
  //         dispatch(loginClient({ email: response?.data?.data.email, password: response?.data?.data.password }));
  //         toast.success(response.data?.message);
  //         return response.data; // Optionally return the response data
  //       }
     
  //     }, (error) => {
  //         console.log(error.response?.data?.message)
  //     })
      
  // };

  export const registerClient = (data, rememberMe = false) => {
    return async (dispatch) => {
      return apiClient()
        .post(`/register`, data)
        .then((response) => {
          if (!response.data?.data?.status) {
            toast.error(response.data?.data?.message); // Show error toast if status is false
            return { success: false, message: response.data?.data?.message };
          } else {
            // Dispatch the login action with the registered user's email and password
            dispatch(loginClient({ email: response?.data?.data.email, password: data.password }));
            toast.success(response.data?.message); // Show success toast
            return { success: true, message: response.data?.data?.message, data: response.data }; // Return the response data
          }
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || "Registration Failed!";
          toast.error(errorMessage); // Show error toast
          return { success: false, message: errorMessage };
        });
    };
  };

export const logoutClient = () => async (dispatch) => {
  dispatch({ type: LOGOUT_CLIENT });
  dispatch({ type: DELETE_ALL_DATA });
};

export const UpdateClient = (data, id) => async (dispatch) => {
  try {
    const response = await apiClient().put(`/users/${id}`, data);
    if (response?.status === 200) {
      toast.success("Vos informations ont été modifiées avec succès.");
      dispatch({ type: UPDATE_CLIENT, payload: response?.data });
      return response?.data;
    }
  } catch (error) {
    console.error("Registration Failed!", error);
  }
};
