import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signalComplaint } from "../../../actions/data";
import { Toaster } from "react-hot-toast";

function ReportDash() {
  const [description, setDescription] = useState("");
  const [sugg, setSugg] = useState(null);
  const dispatch = useDispatch();
  const client = useSelector(state => state?.auth?.client);
  const suggestions = useSelector(state => state?.data?.suggestions);

  useEffect(()=>{
    if(suggestions?.length){
      setSugg(suggestions[0]?.id);
    }
  },[suggestions])

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(!client?.id){
      console.log("client not authenticated !");
      return;
    }
    const data = {
      "user_id": client?.id,
      "admin_id": 1,
      "description": description,
      "complaint_suggestion_id": sugg
    };
    try{
      dispatch(signalComplaint(data));
      e.target.reset();
      setDescription("");
    }catch(err){
      console.error(err);
    }
  }

  return (
    <div className="dashboard-right-contents mt-4 mt-lg-0">
      <div className="dashboard-reservation">
        <div className="single-reservation bg-white base-padding">
          <div className="single-reservation-item">
            <div className="single-reservation-name">
              <h5 className="single-reservation-title">
                Signaler un problème
              </h5>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-2 mt-3">
                  <div className="space-y-1">
                    <label htmlFor="select" className="">Choisissez une raison</label>
                    <select onChange={e=>setSugg(parseInt(e.target.value))} name="select" id="select" className="w-full p-2.5 border outline-none focus:outline-none">
                      {suggestions?.map(sugg=>(
                        <option value={sugg?.id} selected={sugg?.id === sugg}>
                          {sugg?.suggestion}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="raison" className=""> Décrivez votre raison</label>
                    <textarea id="raison" name="description" required={true} value={description} onChange={(e)=>setDescription(e.target.value)} className="w-full p-2 border outline-none focus:outline-none" placeholder="Détails du description" rows="5"></textarea>
                  </div>
                </div>
                <div className="btn-wrapper mt-3">
                  <button className="cmn-btn btn-bg-1 radius-10">
                    Soumettre le problème
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toaster/>
    </div>
  );
}

export default ReportDash;
