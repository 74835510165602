import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import StarIcon from "@mui/icons-material/Star";
import Slider from "@mui/material/Slider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL_IMAGE } from "../../constants/actions";
import Checkbox from "@mui/material/Checkbox";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  Search,
  SlidersHorizontal,
  ChevronDown,
  ChevronUp,
  Star,
  Building2,
  CircleDollarSign,
  Briefcase,
  MapPin,
} from "lucide-react";
import { Grid, List, ChevronLeft, ChevronRight } from "lucide-react";

function SideBarListing() {
  const data = useSelector((state) => state?.data);
  const isSmallScreen = useMediaQuery("(max-width:990px)");
  const [filtredSalons, setFilteredSalons] = useState(data?.salons || []);
  const [showsSettings, setShowSettings] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [openSection, setOpenSection] = useState({
    price: true,
    city: true,
    services: true,
    rating: true,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const itemsPerPage = 4;
  const maxVisibleButtons = 5;

  const handleClickSettings = () => {
    setShowSettings(!showsSettings);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleServiceChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedServices((prevSelectedServices) =>
      prevSelectedServices?.includes(value)
        ? prevSelectedServices?.filter((service) => service !== value)
        : [...prevSelectedServices, value]
    );
  };

  const [TypeOfList, setTypeOfList] = useState("grid");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const ratings = [
    { id: 5, stars: 5 },
    { id: 4, stars: 4 },
    { id: 3, stars: 3 },
    { id: 2, stars: 2 },
    { id: 1, stars: 1 },
  ];

  const cities =
    [...new Set(data?.salons?.map((salon) => salon?.city?.toLowerCase()))] ||
    [];

  const handleChangeTypeList = (e, tp) => {
    e.preventDefault();
    setTypeOfList(tp);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth <= 768) {
      setTypeOfList("grid");
    }
  }, [screenWidth]);

  // const toggleSection = (section) => {
  //   setOpenSection((prevOpenSection) => ({
  //     ...prevOpenSection,
  //     [section]: !prevOpenSection[section],
  //   }));
  // };

  const handleRatingChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedRatings((prevSelectedRatings) =>
      prevSelectedRatings.includes(value)
        ? prevSelectedRatings.filter((rating) => rating !== value)
        : [...prevSelectedRatings, value]
    );
  };

  // const renderStars = (count) => {
  //   return Array.from({ length: count }, (_, i) => (
  //     <StarIcon style={{ color: "#FFD700", fontSize: "28px" }} />
  //   ));
  // };

  const handleSearchInput = (e) => {
    e.preventDefault();
  };

  const SearchByInput = (data, searchInput) => {
    data = data.filter((salon) =>
      salon?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
    return data;
  };

  const SearchByCategorie = (data, categorie) => {
    data = data?.filter((salon) =>
      salon?.services?.some((service) => service?.categorie === categorie)
    );
    return data;
  };

  const SearchByServices = (data, services) => {
    data = data.filter((salon) =>
      salon?.services?.some((service) => services?.includes(service?.id))
    );
    return data;
  };

  const SearchByCity = (data, city) => {
    data = data.filter((salon) =>
      salon?.city?.toLowerCase()?.includes(city.toLowerCase())
    );
    return data;
  };

  const SearchByRatings = (data, ratings) => {
    data = data.filter((salon) =>
      ratings.includes(Math.floor(salon?.average_rating))
    );
    return data;
  };

  const SearchByPrice = (data, priceRange) => {
    data = data.filter((salon) => {
      const minPrice = salon?.services?.sort(
        (a, b) => parseFloat(a?.pivot?.price) - parseFloat(b?.pivot?.price)
      )[0]?.pivot?.price;
      return minPrice >= priceRange[0] && minPrice <= priceRange[1];
    });
    return data;
  };

  useEffect(() => {
    let filtred = data?.salons || [];

    if (searchInput?.trim()) {
      filtred = SearchByInput(filtred, searchInput);
    }
    if (selectedServices?.length) {
      filtred = SearchByServices(filtred, selectedServices);
    }

    if (selectedCity?.trim()) {
      filtred = SearchByCity(filtred, selectedCity);
    }

    if (selectedRatings?.length) {
      filtred = SearchByRatings(filtred, selectedRatings);
    }

    if (priceRange?.length) {
      filtred = SearchByPrice(filtred, priceRange);
    }
    setFilteredSalons(filtred);
  }, [
    searchInput,
    selectedServices,
    selectedCity,
    selectedRatings,
    priceRange,
    data?.salons,
  ]);

  useEffect(() => {
    let filtred = data?.salons;
    if (location?.state?.searchInput) {
      setSearchInput(location?.state?.searchInput);
      filtred = SearchByInput(filtred, location?.state?.searchInput);
    } else if (location?.state?.categorie) {
      if (location?.state?.categorie) {
        filtred = SearchByCategorie(filtred, location?.state?.categorie);
      }
    } else {
      if (location?.state?.city) {
        setSelectedCity(location?.state?.city);
        filtred = SearchByCity(filtred, location?.state?.city);
      }
      if (location?.state?.service) {
        setSelectedServices([
          ...selectedServices,
          location?.state?.service?.id,
        ]);
        filtred = SearchByServices(filtred, [location?.state?.service?.id]);
      }
    }
    setFilteredSalons(filtred);
  }, [location?.state, data?.salons]);

  const totalPages = Math.ceil(filtredSalons?.length / itemsPerPage);

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [filtredSalons]);

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filtredSalons?.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filtredSalons]);

  const FilterSection = ({ title, isOpen, onToggle, icon: Icon, children }) => (
    <div className="bg-white rounded-lg shadow-sm p-3 mb-4">
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between text-gray-800 font-medium"
      >
        <div className="flex items-center gap-2">
          <Icon size={20} className="text-gray-600" />
          <span>{title}</span>
        </div>
        {isOpen ? (
          <ChevronUp size={20} className="text-gray-600" />
        ) : (
          <ChevronDown size={20} className="text-gray-600" />
        )}
      </button>
      {isOpen && <div className="mt-4 space-y-2">{children}</div>}
    </div>
  );

  const toggleSection = (section) => {
    setOpenSection((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const renderStars = (count) => {
    return Array(count)
      .fill(0)
      .map((_, index) => (
        <Star
          key={index}
          size={16}
          className="fill-yellow-400 text-yellow-400"
        />
      ));
  };

  return (
    <div className="listingpagev2 mb-5">
      <div className="package-grid-with-sidebar-section pt-120 mb-120">
        <div className="container">
          <div className="row g-lg-4 gy-5">
            <div className="col-lg-4 max-w-xs">
              <div className="space-y-4">
                {/* Search Bar */}
                <div className="relative">
                  <form onSubmit={handleSearchInput} className="relative">
                    <input
                      type="text"
                      placeholder="Rechercher..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                    />
                    <Search
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={18}
                    />
                    {isSmallScreen && (
                      <button
                        type="button"
                        onClick={handleClickSettings}
                        className="absolute right-3 top-1/2 -translate-y-1/2"
                      >
                        <SlidersHorizontal
                          size={18}
                          className="text-gray-400"
                        />
                      </button>
                    )}
                  </form>
                </div>

                {/* Filter Sections */}
                {(!isSmallScreen || showsSettings) && (
                  <div className="mt-4">
                    {/* Services Section */}
                    <FilterSection
                      title="Services"
                      isOpen={openSection.services}
                      onToggle={() => toggleSection("services")}
                      icon={Briefcase}
                    >
                      {data?.services?.map((service) => (
                        <label
                          key={service.id}
                          className="flex items-center space-x-2 hover:bg-gray-50 px-2 py-1 rounded-md cursor-pointer"
                        >
                          <Checkbox
                            size="small"
                            checked={selectedServices.includes(service.id)}
                            onChange={(e) =>
                              handleServiceChange({
                                target: {
                                  value: service.id,
                                  checked: e.target.checked,
                                },
                              })
                            }
                            sx={{
                              color: "#d1d5db",
                              "&.Mui-checked": {
                                color: "#f97316",
                              },
                            }}
                          />
                          <span className="text-sm text-gray-700 capitalize">
                            {service.name}
                          </span>
                        </label>
                      ))}
                    </FilterSection>

                    {/* Price Range Section */}
                    <FilterSection
                      title="Prix"
                      isOpen={openSection.price}
                      onToggle={() => toggleSection("price")}
                      icon={CircleDollarSign}
                    >
                      <div className="px-1">
                        <Slider
                          value={priceRange}
                          onChange={handlePriceChange}
                          valueLabelDisplay="auto"
                          min={0}
                          max={3000}
                          sx={{
                            "& .MuiSlider-thumb": {
                              color: "#f97316",
                            },
                            "& .MuiSlider-track": {
                              color: "#f97316",
                            },
                            "& .MuiSlider-rail": {
                              color: "#e5e7eb",
                            },
                          }}
                        />
                        <div className="text-sm text-gray-600 text-center">
                          Prix: {priceRange[0]} Dhs - {priceRange[1]} Dhs
                        </div>
                      </div>
                    </FilterSection>

                    {/* City Section */}
                    <FilterSection
                      title="Ville"
                      isOpen={openSection.city}
                      onToggle={() => toggleSection("city")}
                      icon={MapPin}
                    >
                      <label className="flex items-center space-x-3 hover:bg-gray-50 p-2 rounded-md cursor-pointer">
                        <input
                          type="radio"
                          value=""
                          checked={selectedCity === ""}
                          onChange={(e) => setSelectedCity(e.target.value)}
                          className="text-orange-500 focus:ring-orange-500"
                        />
                        <span className="text-sm text-gray-700">
                          Toutes les villes
                        </span>
                      </label>
                      {cities?.map((city, index) => (
                        <label
                          key={index}
                          className="flex items-center space-x-3 hover:bg-gray-50 p-2 rounded-md cursor-pointer"
                        >
                          <input
                            type="radio"
                            value={city}
                            checked={selectedCity === city}
                            onChange={(e) => setSelectedCity(e.target.value)}
                            className="text-orange-500 focus:ring-orange-500"
                          />
                          <span className="text-sm text-gray-700 capitalize">
                            {city}
                          </span>
                        </label>
                      ))}
                    </FilterSection>

                    {/* Rating Section */}
                    <FilterSection
                      title="Filtrer par note"
                      isOpen={openSection.rating}
                      onToggle={() => toggleSection("rating")}
                      icon={Star}
                    >
                      {ratings.map((rating) => (
                        <label
                          key={rating.id}
                          className="flex items-center hover:bg-gray-50 p-2 rounded-md cursor-pointer"
                        >
                          <Checkbox
                            size="small"
                            checked={selectedRatings.includes(rating.stars)}
                            onChange={(e) =>
                              handleRatingChange({
                                target: {
                                  value: rating.stars,
                                  checked: e.target.checked,
                                },
                              })
                            }
                            sx={{
                              color: "#d1d5db",
                              "&.Mui-checked": {
                                color: "#f97316",
                              },
                            }}
                          />
                          <div className="flex items-center space-x-1">
                            {renderStars(rating.stars)}
                          </div>
                        </label>
                      ))}
                    </FilterSection>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-8 max-w-7xl">
              {(data?.salon?.length > 0 || currentItems.length > 0) &&
                screenWidth > 991 && (
                  <div className="flex justify-end mb-6">
                    <div className="flex gap-2 bg-gray-100 p-1 rounded-lg">
                      <button
                        onClick={(e) => handleChangeTypeList(e, "grid")}
                        className={`p-2 rounded-md transition-all ${
                          TypeOfList === "grid"
                            ? "bg-white shadow-sm"
                            : "hover:bg-gray-200"
                        }`}
                      >
                        <Grid size={18} className="text-gray-600" />
                      </button>
                      <button
                        onClick={(e) => handleChangeTypeList(e, "list")}
                        className={`p-2 rounded-md transition-all ${
                          TypeOfList === "list"
                            ? "bg-white shadow-sm"
                            : "hover:bg-gray-200"
                        }`}
                      >
                        <List size={18} className="text-gray-600" />
                      </button>
                    </div>
                  </div>
                )}

              <div
                className={`grid gap-6 ${
                  TypeOfList === "grid"
                    ? "md:grid-cols-2 lg:grid-cols-2"
                    : "grid-cols-1"
                }`}
              >
                {currentItems.length > 0 ? (
                  currentItems.map((item, index) => (
                    <div
                      key={index}
                      className={`bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow overflow-hidden ${
                        TypeOfList === "list" ? "flex gap-4" : ""
                      }`}
                    >
                      <div
                        className={`relative ${
                          TypeOfList === "list" ? "w-1/3" : "w-full"
                        }`}
                      >
                        <img
                          src={BASE_URL_IMAGE + item?.image}
                          alt={item?.name}
                          className="w-full h-48 object-cover"
                        />
                        <div className="absolute top-4 left-4 flex items-center gap-2 bg-white/90 backdrop-blur-sm px-2 py-1 rounded-full">
                          <Star className="w-4 h-4 text-yellow-500 fill-yellow-500" />
                          <span className="text-sm font-medium">
                            {item?.average_rating}
                          </span>
                        </div>
                        <div className="absolute top-4 right-4 flex items-center gap-2 bg-white/90 backdrop-blur-sm px-2 py-1 rounded-full">
                          <MapPin className="w-4 h-4 text-gray-600" />
                          <span className="text-sm font-medium">
                            {item?.city}
                          </span>
                        </div>
                      </div>

                      <div
                        className={`p-4 flex flex-col ${
                          TypeOfList === "list" ? "w-2/3" : "w-full"
                        }`}
                      >
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                          {item?.name}
                        </h3>
                        <div className="text-sm text-gray-600 mb-4 overflow-x-auto whitespace-nowrap">
                          {item?.services?.map((ser) => ser?.name)?.join(" • ")}
                        </div>

                        <div
                          className={`mt-auto flex ${
                            TypeOfList === "list"
                              ? "items-center justify-between"
                              : "flex-col gap-4"
                          }`}
                        >
                          <div>
                            <div className="text-sm text-gray-600">
                              À partir de
                            </div>
                            <div className="text-xl font-bold text-gray-900">
                              {
                                item?.services?.sort(
                                  (a, b) =>
                                    parseFloat(a?.pivot?.price) -
                                    parseFloat(b?.pivot?.price)
                                )[0]?.pivot?.price
                              }{" "}
                              Dhs
                            </div>
                            <div className="text-xs text-gray-500">
                              PAR/PERS
                            </div>
                          </div>

                          <button
                            onClick={() =>
                              navigate(`/detail-page`, {
                                state: {
                                  id: item?.id,
                                  prevPath: location?.pathname,
                                },
                              })
                            }
                            className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-2 rounded-lg transition-colors"
                          >
                            Réserver maintenant
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-full flex justify-center p-8">
                    <div className="text-center bg-orange-50 p-8 rounded-xl max-w-md">
                      <Search className="w-16 h-16 text-orange-500 mx-auto mb-4" />
                      <h2 className="text-xl font-semibold text-gray-900 mb-2">
                        Aucun résultat trouvé
                      </h2>
                      <p className="text-gray-600">
                        Nous n'avons pas pu trouver de résultats correspondant à
                        votre recherche. Veuillez essayer de nouveau avec
                        d'autres critères.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {data?.salons?.length > itemsPerPage &&
                currentItems?.length > 0 && (
                  <div className="flex justify-center mt-8">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`p-2 rounded-lg transition-colors ${
                          currentPage === 1
                            ? "bg-gray-100 text-gray-400"
                            : "bg-white hover:bg-gray-50 text-gray-600"
                        }`}
                      >
                        <ChevronLeft className="w-5 h-5" />
                      </button>

                      {visiblePages.map((page) => (
                        <button
                          key={page}
                          onClick={() => handlePageChange(page)}
                          className={`min-w-[40px] h-10 rounded-lg transition-colors ${
                            currentPage === page
                              ? "bg-orange-500 text-white"
                              : "bg-white hover:bg-gray-50 text-gray-600"
                          }`}
                        >
                          {page}
                        </button>
                      ))}

                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`p-2 rounded-lg transition-colors ${
                          currentPage === totalPages
                            ? "bg-gray-100 text-gray-400"
                            : "bg-white hover:bg-gray-50 text-gray-600"
                        }`}
                      >
                        <ChevronRight className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBarListing;
