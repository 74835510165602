const AddMinutesToTime = (timeString, durationString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
    // const durationHours = parseInt(durationString?.slice(0, 2), 10) || 0;
    // const durationMinutes = parseInt(durationString?.slice(2, 4), 10) || 0;
    // const totalMinutesToAdd = (durationHours * 60) + durationMinutes;
  
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    time.setSeconds(seconds);
  
    time.setMinutes(time.getMinutes() + durationString);
  
    const newHours = String(time.getHours()).padStart(2, '0');
    const newMinutes = String(time.getMinutes()).padStart(2, '0');
    const newSeconds = String(time.getSeconds()).padStart(2, '0');
  
    return `${newHours}:${newMinutes}:${newSeconds}`;
  }
  
  export default AddMinutesToTime;
  