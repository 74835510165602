import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
// import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import SearchIcon from '@mui/icons-material/Search';

function SearchPart2({ props }) {
  return (
    <div className="banner-location banner-location-one bg-white">
      <div className="banner-location-flex">
      <div className="banner-location-single">
          <div className="banner-location-single-flex">
            <div className="banner-location-single-icon">
              <SearchIcon />
            </div>
            <div className="banner-location-single-contents">
                <input className={`js-select select-style-two`} placeholder="Recherche par établissement" type="text" value={props?.nomEtablissement} onChange={(e) => props?.setNomEtablissement(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="banner-location-single-search">
          <div className="search-suggestions-wrapper" onClick={()=>props?.handleSearch(2)}>
            <div className="search-click-icon">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchPart2;
