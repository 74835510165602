import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import backappbg from "../../assets/images/downloadappbg.png";
import './style.scss';

function DownloadApp({ title, description }) {
  return (
    <section className="download-app-section">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h2 className="download-title">{title}</h2>
            <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
            <div className="list-download">
              <div className="button-download" aria-label="Download on Apple Store">
                <AppleIcon />
                <div className="groupe-tele">
                  <span className='text-center'>Télécharger sur</span>
                  <span>Apple Store</span>
                </div>
              </div>
              <div className="button-download" aria-label="Get it on Google Play">
                <ShopIcon />
                <div className="groupe-tele">
                  <span>Participez</span>
                  <span>Google Play</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <img src={backappbg} alt="Download app background" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadApp;