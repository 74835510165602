import './style.scss'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';

function EntetePage({title}) {
  return (
    <div className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="banner-content">
                <h1>{title} </h1>
                <ul id="breadcrumb-list" className="breadcrumb-list">
                  <li className="breadcrumb-item">
                    <Link to={"/"}> Accueil </Link>
                  </li>
                  <li className="active"><ArrowRightAltIcon /><span>{title}</span></li>
                </ul>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default EntetePage