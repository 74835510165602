import React from "react";
import CommentIcon from "@mui/icons-material/Comment";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from "react-router-dom";
import { BASE_URL_IMAGE } from "../../constants/actions";

function SingleBlog({
  id,
  dateblog,
  imgp,
  titleblog,
  description,
  nbcomment,
  typeblog,
  author,
  fromSingle
}) {
  const navigate = useNavigate();
  const handleNavigateToBlog = (e) => {
    e.preventDefault();
    navigate(`/blog-detail/${id}`);
  };

  return (
    <div className="single-blog">
      <img src={BASE_URL_IMAGE + imgp} alt="img" />
      <span className="title">{titleblog}</span>
      <div className="tags-comment-date">
        <div>
          <CalendarMonthIcon />
          <span>{dateblog}</span>
        </div>
        <div>
          <FolderOpenIcon />
          <span>{typeblog}</span>
        </div>
        <div>
          <CommentIcon />
          <span>{nbcomment ? nbcomment : 0} Commentaires</span>
        </div>
        
        
      </div>
      <div className="author">
        <CreateIcon />
        <span>{author}</span>
      </div>
      {!fromSingle && <div className="desc-blog text-justify" dangerouslySetInnerHTML={{ __html: description }} />}
      {!fromSingle && <button type="button" onClick={(e) => handleNavigateToBlog(e)}>
        En savoir plus
      </button>}
    </div>
  );
}

export default SingleBlog;
