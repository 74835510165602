import React, { useEffect, useState } from "react";
import {
  DashMain,
  EditProfile,
  PasswordProfile,
  Profile,
  ReportDash,
} from "../../components/dashboardcomp";
import { Link, useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./style.scss";
import { useDispatch } from "react-redux";
import { logoutClient } from "../../actions/auth";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Dashboard() {
  const [selectedOne, setSelectedOne] = useState("dash");
  const [openProfile, setOpenProfile] = useState(false);
  const [menuOpCl, SetMenuOpCl] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSelectedWhat = (e, selo) => {
    e.preventDefault();
    setSelectedOne(selo);
    SetMenuOpCl(false);
  };

  const handleOpenCloseProfile = () => {
    setOpenProfile(!openProfile);
  };

  useEffect(() => {
    if (location?.state?.selone) {
      setSelectedOne(location?.state?.selone);
    }
  }, [location]);

  const handleGetWhichComp = (selone) => {
    switch (selone) {
      case "dash":
        return <DashMain />;
      case "report":
        return <ReportDash />;
      case "profile":
        return <Profile setSelectedOne={setSelectedOne}/>;
      case "edit-profile":
        return <EditProfile />;
      case "password":
        return <PasswordProfile />;
      default:
        break;
    }
  };

  const handleOpenCloseMenu = () => {
    SetMenuOpCl(true);
  };

  const handleOpenCloseMenuc = () => {
    SetMenuOpCl(false);
  };

  const logout = () => {
    dispatch(logoutClient());
  };

  return (
    <div className="dashboard-area section-bg-2 dashboard-padding">
      <div className="container">
        <div className="dashboard-contents-wrapper">
          <div className="dashboard-icon">
            <div className="sidebar-icon" onClick={handleOpenCloseMenu}>
              <MenuIcon />
            </div>
          </div>
          <div className={`dashboard-left-content ${menuOpCl && "active"}`}>
            <div className="dashboard-close-main">
              <div className="close-bars" onClick={handleOpenCloseMenuc}>
                {" "}
                <CloseIcon />{" "}
              </div>
              <div className="dashboard-bottom">
                <ul className="dashboard-list list-style-none">
                  <li
                    className={`list ${selectedOne === "dash" && "active "}`}
                    onClick={(e) => handleSelectedWhat(e, "dash")}
                  >
                    <Link>
                      {" "}
                      <WorkIcon /> Tableau de bord{" "}
                    </Link>
                  </li>
                  <li
                    className={`list has-children ${
                      selectedOne === "profile" ||
                      selectedOne === "edit-profile" ||
                      selectedOne === "password"
                        ? "active"
                        : ""
                    } ${openProfile ? "open" : ""}`}
                    onClick={() => handleOpenCloseProfile()}
                  >
                    <Link>
                      {" "}
                      <AccountCircleIcon /> <span>Profil</span>{" "}
                      {!openProfile ? <AddIcon /> : <RemoveIcon />}
                    </Link>
                    <ul
                      className="submenu list-style-none"
                      style={{ display: openProfile ? "block" : "none" }}
                    >
                      <li
                        className={`list ${
                          selectedOne === "profile" && "selected "
                        }`}
                        onClick={(e) => handleSelectedWhat(e, "profile")}
                      >
                        {" "}
                        <Link> Profil </Link>{" "}
                      </li>
                      <li
                        className={`list ${
                          selectedOne === "edit-profile" && "selected "
                        }`}
                        onClick={(e) => handleSelectedWhat(e, "edit-profile")}
                      >
                        {" "}
                        <Link> Modifier le profil </Link>{" "}
                      </li>
                      <li
                        className={`list ${
                          selectedOne === "password" && "selected"
                        }`}
                        onClick={(e) => handleSelectedWhat(e, "password")}
                      >
                        {" "}
                        <Link> Changer le mot de passe </Link>{" "}
                      </li>
                    </ul>
                  </li>

                  {/* <li className={`list has-children ${(selectedOne === 'support' || selectedOne === 'tickets' || selectedOne === 'addtickets') && 'active'} ${openSupport ? 'open' : ''}`} onClick={() => handleOpenCloseSupport()}>
                          <Link> <i className="las la-headset"></i> Support </Link>
                          <ul className="submenu list-style-none" style={{display: openSupport ? 'block' : 'none'}}>
                              <li className={`list ${selectedOne === 'support' && 'selected '}`} onClick={(e) => handleSelectedWhat(e, 'support')}> <Link> Support </Link> </li>
                              <li className={`list ${selectedOne === 'tickets' && 'selected '}`} onClick={(e) => handleSelectedWhat(e, 'tickets')}> <Link> Tickets  </Link> </li>
                              <li className={`list ${selectedOne === 'addtickets' && 'selected '}`} onClick={(e) => handleSelectedWhat(e, 'addtickets')}> <Link> Add Tickets  </Link> </li>
                          </ul>
                      </li> */}
                  <li className={`list`} onClick={logout}>
                    <div>
                      <LogoutIcon /> Déconnexion
                    </div>
                  </li>
                  <li
                    className={`list ${selectedOne === "report" && "active"}`}
                    onClick={(e) => handleSelectedWhat(e, "report")}
                  >
                    <div>
                      <ReportProblemIcon /> Signaler un problème
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {handleGetWhichComp(selectedOne)}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
