import React, { useEffect, useState } from "react";
import SalonItem from "../salonitem";
import "./style.scss";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
import useCarousel from "../../helpers/useCarousel";
import { ChevronLeft, ChevronRight } from "lucide-react";

function TopSalon({ title, topSalons }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const salons = useSelector((state) => state?.data?.salons);
  const [mellieurSalons, setMellieurSalons] = useState([]);

  useEffect(() => {
    if (!salons) return;

    let getSalons = [];

    if (topSalons) {
      const topSalonsArray = topSalons?.split(",")?.map(Number);
      getSalons = salons?.filter((sal) => topSalonsArray?.includes(sal?.id));
    }

    if (!topSalons || getSalons.length === 0) {
      getSalons = [...salons]
        .sort(
          (a, b) => parseFloat(b.average_rating) - parseFloat(a.average_rating)
        )
        .slice(0, 4);
    }

    setMellieurSalons(getSalons);
  }, [salons, topSalons]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { currentIndex, next, prev } = useCarousel(mellieurSalons || []);

  if (screenWidth <= 768) {
    return (
      <div className="shop-grid-contents">
      <div id="tab-grid" className="tab-content-item active mt-4">
        <div className="section-title-three append-flex container">
          <h2 className="title"> {title} </h2>
        </div>
        <br />
        <br />
        <br />
      <div className="relative px-8">
        <div className="overflow-hidden">
          <div
            className="transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            <div className="flex">
              {mellieurSalons?.map((salon, index) => (
                <div
                  key={index}
                  className="w-full flex-shrink-0"
                  style={{
                    marginRight:
                      index < mellieurSalons.length - 1 ? "1rem" : "0",
                  }}
                >
                  <SalonItem
                    id={salon?.id}
                    average_rating={salon?.average_rating}
                    reservationsLength={salon?.bookings?.length}
                    namesalon={salon?.name}
                    adresse={salon?.address + ", " + salon?.city}
                    price={
                      salon?.services?.sort(
                        (a, b) =>
                          parseFloat(a?.pivot?.price) - parseFloat(b.price)
                      )[0]?.pivot?.price
                    }
                    services={salon?.services
                      ?.map((ser) => ser?.name)
                      ?.join(", ")}
                    imagesalon={salon?.image}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <button
          onClick={prev}
          className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
        >
          <ChevronLeft className="w-5 h-5 text-orange-600" />
        </button>

        <button
          onClick={next}
          className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
        >
          <ChevronRight className="w-5 h-5 text-orange-600" />
        </button>

        <div className="flex justify-center gap-2 mt-4">
          {mellieurSalons?.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 rounded-full transition-colors ${
                currentIndex === index ? "bg-orange-500" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
      </div>
      </div>
    );
  }

  return (
    // <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
      
    // </div>
    <div className="shop-grid-contents">
      <div id="tab-grid" className="tab-content-item active mt-4">
        <div className="section-title-three append-flex container">
          <h2 className="title"> {title} </h2>
        </div>
        <br />
        <br />
        <br />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 px-10">


        {mellieurSalons?.map((salon, index) => (
        <SalonItem
          key={index}
          id={salon?.id}
          average_rating={salon?.average_rating}
          reservationsLength={salon?.bookings?.length}
          namesalon={salon?.name}
          adresse={salon?.address + ", " + salon?.city}
          price={
            salon?.services?.sort(
              (a, b) => parseFloat(a?.pivot?.price) - parseFloat(b.price)
            )[0]?.pivot?.price
          }
          services={salon?.services
            ?.slice(0, 3)
            ?.map((ser) => ser?.name)
            ?.join(", ")}
          imagesalon={salon?.image}
        />
      ))}
      </div>
      </div>
    </div>
  );
}

export default TopSalon;