import { useSelector } from 'react-redux';
import { SingleBlog } from '../../components';
import { useParams } from 'react-router-dom';
import './style.scss';
import { BASE_URL_IMAGE } from '../../constants/actions';
import FormatDate2 from '../../helpers/FormatDate2';

function DetailBlogPage() {
  // Get the ID from the URL parameters
  const { id } = useParams();

  // Select the list of blogs from the Redux store
  const blogs = useSelector((state) => state?.data?.blogs) || [];

  // Find the specific blog using the ID from the URL
  const blog = blogs.find((blog) => blog.id === Number(id));

  return (
    <div className='detail-blog-page'>
      <div className="blogs-detail">
        {blog ? (
          <SingleBlog
            key={blog.id}
            id={blog.id}
            dateblog={blog.created_at && FormatDate2(blog.created_at)}
            imgp={`${blog.image}`}  // Assuming BASE_URL_IMAGE is required for image
            titleblog={blog.title}
            description={blog.description}
            nbcomment={blog?.comments?.length} // Assuming you have comments in the blog object
            typeblog={blog.subject}
            blogtext={blog.contenue}  // Use blog.contenue directly
            author={blog.author}
            fromSingle={true}
          />
        ) : (
          <div className="not-found">Blog not found!</div>
        )}

        <div className="contenu" dangerouslySetInnerHTML={{ __html: blog?.contenue }}></div>
      </div>
    </div>
  );
}

export default DetailBlogPage;