import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

function Conditions() {
    const conditions = useSelector(state => state?.data?.conditions);

  return (
    <div className="apropos-page">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: conditions?.contenue }} />
      </div>
    </div>
  );
}

export default Conditions;
