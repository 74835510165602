const FormatDate2 = (dateString) => {
    const months = ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"];

    const date = new Date(dateString);

    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const year = date.getFullYear();

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const period = hours < 12 ? "AM" : "PM";

    return `${month} ${day}, ${formattedHours}:${formattedMinutes} ${period} ${year}`;
}

export default FormatDate2;
