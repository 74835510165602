import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

function Rgbd() {
    const rgbd = useSelector(state => state?.data?.rgbd);

  return (
    <div className="apropos-page">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: rgbd?.contenue }} />
      </div>
    </div>
  );
}

export default Rgbd;
