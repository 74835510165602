import React, { useEffect, useState } from "react";
import "./style.scss";
import { SingleBlog } from "../../components";
import { useSelector } from "react-redux";
import FormatDate2 from "../../helpers/FormatDate2";
import { BASE_URL_IMAGE } from "../../constants/actions";

function BlogPage() {
  const blogdata = useSelector((state) => state?.data?.blogs) || [];
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Toutes catégories");
  const [filteredBlogs, setFilteredBlogs] = useState(blogdata);
  const [popularBlogs, setPopularBlogs] = useState([]);

  useEffect(() => {
    const filterBlogs = () => {
      let filtered = blogdata;
      if (searchQuery) {
        filtered = filtered?.filter((blog) =>
          blog?.title?.toLowerCase().includes(searchQuery?.trim().toLowerCase())
        );
      }
      if (selectedCategory && selectedCategory !== "Toutes catégories") {
        filtered = filtered?.filter(
          (blog) =>
            blog?.subject?.toLowerCase() === selectedCategory.toLowerCase()
        );
      }
      setFilteredBlogs(filtered);
    };

    filterBlogs();
  }, [searchQuery, selectedCategory]);

  useEffect(() => {
    const findPopularBlogs = () => {
      const popular = [...blogdata]
        ?.sort((a, b) => b?.nbcomment - a?.nbcomment)
        .slice(0, 5);
      setPopularBlogs(popular);
    };

    findPopularBlogs();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const categories = [
    "Toutes catégories",
    ...new Set(blogdata?.map((blog) => blog?.subject)),
  ];

  return (
    <div className="blog-page">
      <div className="blogs-list">
        {filteredBlogs?.map((blog) => (
          <SingleBlog
            key={blog?.id}
            id={blog?.id}
            dateblog={blog?.created_at && FormatDate2(blog?.created_at)}
            imgp={blog?.image}
            titleblog={blog?.title}
            description={blog?.description}
            nbcomment={16}
            typeblog={blog?.subject}
            blogtext={blog?.contenue}
            author={blog.author}
          />
        ))}
      </div>
      <div className="blog-meta-data">
        <div className="search">
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="blog-categories">
          <span className="title">Catégories</span>
          <div className="list-categ">
            {categories?.map((category) => (
              <div
                key={category}
                className={selectedCategory === category ? "active" : ""}
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </div>
            ))}
          </div>
        </div>
        <div className="popular-blogs">
          <span className="title">Blogs Populaires</span>
          <div className="list-popular">
            {popularBlogs?.map((blog) => (
              <div key={blog?.id} onClick={() => handleCategoryChange(blog?.subject)}>
                <img src={BASE_URL_IMAGE + blog?.image} alt="img" />
                <div className="title-date">
                  <span>{blog?.title}</span>
                  <span>{blog?.created_at && FormatDate2(blog?.created_at)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
