import { combineReducers } from 'redux';
import reservation from './reservation';
import auth from './auth';
import data from './data';
 
const rootReducer = combineReducers({
    reservation,
    auth,
    data
});

export default rootReducer;