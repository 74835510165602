import React, { useEffect, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/authContext";
import CalculateDuration from "../../helpers/CalculateDuration";
import FormatDateToFrench from "../../helpers/FormateDateToFrensh";
import {
  AttacheServicesToBoking,
  CheckTheAvailabilty,
  CreateAvailability,
  CreateBooking,
  CreatePayment,
  FetchData,
  RegisterGuest,
  SendEmail,
} from "../../actions/data";
import { loginClient, registerClient } from "../../actions/auth";
import { toast, Toaster } from "react-hot-toast";
import { BOOKINGS, INIT_BOOKING } from "../../constants/actions";
import { Lg1 } from "../../assets/images";
import especs from "../../assets/images/payment/de-largent.png"
import paypal from "../../assets/images/payment/paypal.png"
import card from "../../assets/images/payment/credit-card-icons.png"
import PaymentsIcon from '@mui/icons-material/Payments';

function CheckoutPage() {
  const [paymentMethod, setPaymentMethod] = useState("CARD");
  const [mode, setMode] = useState(0);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [venueMarketingConsent, setVenueMarketingConsent] = useState(false);
  const reservation = useSelector((state) => state?.reservation);
  const client = useSelector((state) => state?.auth?.client);
  const [formData, setFormData] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const { isClientAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !reservation?.salon ||
      !reservation?.services?.length ||
      !reservation?.date ||
      !reservation?.start_time ||
      !reservation?.end_time
    ) {
      navigate("/availability");
      return;
    }
  }, [reservation]);

  useEffect(() => {
    isClientAuthenticated ? setMode(0) : setMode(1);
  }, []);

  useEffect(() => {
    if (reservation?.services?.length) {
      const total = reservation?.services?.reduce(
        (sum, service) => sum + parseFloat(service?.pivot?.price),
        0
      );
      setTotalPrice(total.toFixed(2));
    }
  }, [reservation]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSwitchMode = (mode) => {
    setMode(mode);
    setFormData();
  };

  const duration =
    reservation?.start_time &&
    reservation?.end_time &&
    CalculateDuration(reservation?.start_time, reservation?.end_time);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!venueMarketingConsent || !marketingConsent) {
      return toast.error("Vous devez accepter les conditions !");
    }

    if (
      new Date(`${reservation.date}T${reservation.start_time}`) < new Date()
    ) {
      return toast.error(
        "La session que vous souhaitez réserver a déjà débuté."
      );
    }

    const result = await dispatch(
      CheckTheAvailabilty({
        employee_id: reservation?.employee_id,
        date: reservation?.date,
        start_time: reservation?.start_time,
        end_time: reservation?.end_time,
      })
    ).then((response) => response);

    if (result) {
      return toast.error(
        "Cette session est déjà réservée. Veuillez sélectionner une autre session."
      );
    }

    try {
      setLoading(true);
      let guest = null;
      let availability = null;
      let booking = null;
      let payment = null;
      let newclient = null;
      let responseEmail = null;

      if (mode === 1) {
        guest = await dispatch(
          RegisterGuest({
            name: formData?.name,
            email: formData?.email,
            phone: formData?.phone,
          })
        ).then((response) => response);
      } else if (mode === 2) {
        if (formData?.password !== formData?.confirmePassword) {
          return toast.error("Les mots de passe ne correspondent pas !");
        }

        newclient = await dispatch(
          registerClient({
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            email: formData?.email,
            phone: formData?.phone,
            password: formData?.password,
          })
        ).then((response) => response);
      } else if (mode === 3) {
        newclient = await dispatch(
          loginClient({
            email: formData?.email,
            password: formData?.password,
          })
        ).then((response) => response);
      } else {
        newclient = client;
      }

      availability = await dispatch(
        CreateAvailability({
          salon_id: reservation?.salon?.id,
          date: reservation?.date,
          start_time: reservation?.start_time,
          end_time: reservation?.end_time,
          employee_id: reservation?.employee_id,
        })
      ).then((response) => response);

      booking = await dispatch(
        CreateBooking({
          user_id: guest ? null : newclient?.id || client?.id,
          guest_id: guest ? guest?.id : null,
          salon_id: reservation?.salon?.id,
          availability_id: availability?.id,
          note: formData?.appointmentNotes,
        })
      ).then((response) => response);

      await dispatch(
        AttacheServicesToBoking(booking?.id, reservation?.services)
      );

      payment = await dispatch(
        CreatePayment({
          booking_id: booking?.id,
          amount: totalPrice,
          payment_method: paymentMethod,
          transaction_id: "TRANSACTION_ID",
        })
      ).then((response) => response);

      responseEmail = await dispatch(
        SendEmail({
          type: "reservation",
          name: newclient
            ? `${newclient?.first_name} ${newclient?.last_name}`
            : guest?.name,
          email: newclient?.email || guest?.email,
          services: reservation?.services?.map((ser) => ser?.name)?.join(", "),
          date: reservation?.date,
          debut: reservation?.start_time?.split(":").slice(0, 2).join(":"),
          fin: reservation?.end_time?.split(":").slice(0, 2).join(":"),
          totalPrice: totalPrice,
        })
      ).then((response) => response);

      setFormData(null);

      toast.success("La réservation a été créée avec succès !", {
        autoClose: 400,
        onClose: () => {
          if (!guest) {
            dispatch(
              FetchData(
                `/clientBookings/${newclient?.id || client?.id}`,
                BOOKINGS
              )
            );
          }
          dispatch({ type: INIT_BOOKING });
        },
      });
      setLoading(false);
      setActive(false);
    } catch (err) {
      console.error(err);
      toast.error(
        "Une erreur est survenue lors de la création de la réservation."
      );
      setLoading(false);
      setActive(true);
    }
  };

  return (
    <div className="checkout-page">
      <div className="data-client">
        <div className="title-pay">Paiement</div>
        <form onSubmit={handleSubmit}>
          {mode !== 0 && (
            <div className="modes">
              <button
                type="button"
                className={`${mode === 1 ? "active text-white" : "text-black"}`}
                onClick={() => handleSwitchMode(1)}
              >
                Invité
              </button>
              <button
                type="button"
                className={`${mode === 2 ? "active text-white" : "text-black"}`}
                onClick={() => handleSwitchMode(2)}
              >
                S'inscrire
              </button>
              <button
                type="button"
                className={`${mode === 3 ? "active text-white" : "text-black"}`}
                onClick={() => handleSwitchMode(3)}
              >
                Déjà inscré
              </button>
            </div>
          )}
          {mode === 1 && (
            <div className="data-guest">
              <div className="info-line">
                <span>Mode invité</span>
                <span>* Champ requis</span>
              </div>
              <div className="">
                <label htmlFor="name">Prénom et nom *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData?.name || ""}
                  onChange={handleChange}
                  placeholder="Votre prénom et nom"
                  required={true}
                />
              </div>
              <div>
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData?.email || ""}
                  onChange={handleChange}
                  placeholder="Votre email"
                  required={true}
                />
              </div>
              <div>
                <label htmlFor="telephone">Numéro de téléphone *</label>
                <input
                  type="tel"
                  id="telephone"
                  name="phone"
                  value={formData?.phone || ""}
                  onChange={handleChange}
                  placeholder="ex: 06 12 34 56 78"
                  required={true}
                />
              </div>
            </div>
          )}
          {mode === 2 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <span className="text-lg font-semibold text-[#000]"></span>
                <span className="font-semibold text-sm text-[#f79d68]">
                  * Champ requis
                </span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-3.5">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="first_name"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Prénom *
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData?.first_name || ""}
                    onChange={handleChange}
                    placeholder="Votre prénom"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="last_name"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Nom *
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={formData?.last_name || ""}
                    onChange={handleChange}
                    placeholder="Votre nom"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData?.email || ""}
                    onChange={handleChange}
                    placeholder="Votre email"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="phone"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Numéro de téléphone *
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData?.phone || ""}
                    onChange={handleChange}
                    placeholder="ex: 06 12 34 56 78"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="password"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Mot de passe *
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData?.password || ""}
                    onChange={handleChange}
                    placeholder="Votre mot de passe"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="confirmePassword"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Confirmer Mot de passe *
                  </label>
                  <input
                    type="password"
                    id="confirmePassword"
                    name="confirmePassword"
                    value={formData?.confirmePassword || ""}
                    onChange={handleChange}
                    placeholder="Confirmer votre mot de passe"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
              </div>
            </div>
          )}
          {mode === 3 && (
            <div className="space-y-5">
              <div className="flex items-center justify-between">
                <span className="text-lg font-semibold text-[#000]"></span>
                <span className="font-semibold text-sm text-[#f79d68]">
                  * Champ requis
                </span>
              </div>
              <div className="grid grid-cols-1 gap-x-2 gap-y-3.5">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData?.email || ""}
                    onChange={handleChange}
                    placeholder="Votre email"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="password"
                    className="font-semibold text-sm text-[#000]"
                  >
                    Mot de passe *
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData?.password || ""}
                    onChange={handleChange}
                    placeholder="Votre mot de passe"
                    className="py-2 px-2.5 text-sm border border-[#ccc] rounded-md"
                    required={true}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="data-guest">
            <div className="">
              <label htmlFor="appointmentNotes">
                Notes liées au rendez-vous (Optionnel)
              </label>
              <textarea
                id="appointmentNotes"
                name="appointmentNotes"
                value={formData?.appointmentNotes || ""}
                onChange={handleChange}
                placeholder="Vos notes..."
              />
            </div>
          </div>
          <div className="payment-box">
            <div className="payment-info">
              <span className="payment-title">Choix du paiment</span>
              <span className="payment-description">
                Payez votre traitement à l'avance afin de ne pas avoir à gérer
                d'argent liquide sur place.
              </span>
            </div>
            <div className="payment-method">
              <div className="payment-method-info">
                <p className="payment-method-description">
                  Choisissez votre mode de paiement :
                </p>
              </div>
              <RadioGroup
                name="paymentMethod"
                value={paymentMethod}
                onChange={(event) => setPaymentMethod(event.target.value)}
                className="group-cust-checks"
              >
                <FormControlLabel
                  className="cust-check-input w-full"
                  value="CARD"
                  control={<Radio sx={{ color: "var(--main-color-one)" }} />}
                  label={
                    <span className="formselectpay" style={{ fontFamily: "CustomFont" }}>
                      <span>Payer par carte</span>
                      <img src={card} style={{width: "76px"}} />
                    </span>
                  }
                />
                <FormControlLabel
                  className="cust-check-input w-full"
                  value="PAYPAL"
                  control={<Radio sx={{ color: "var(--main-color-one)" }} />}
                  label={
                    <span className="formselectpay" style={{ fontFamily: "CustomFont" }}>
                      <span>Payer avec PayPal</span>
                      <img src={paypal} style={{width: "60px"}} />
                    </span>
                  }
                />
                <FormControlLabel
                  className="cust-check-input w-full"
                  value="CASH"
                  control={<Radio sx={{ color: "var(--main-color-one)" }} />}
                  label={
                    <span className="formselectpay" style={{ fontFamily: "CustomFont" }}>
                      <span>Payer en espèces à l'arrivée</span>
                      <PaymentsIcon style={{ fontSize: "30px", marginRight: '8px', color: "var(--main-color-one)" }} />
                    </span>
                  }
                />
              </RadioGroup>
              <div className="choice">
                {paymentMethod === "CARD" ? (
                  <div className="card-choice">
                    <span>Entrez les détails de votre carte</span>
                    <div>
                      <label htmlFor="nmcard">Numéro de carte *</label>
                      <input
                        type="text"
                        name="nmcard"
                        id="nmcard"
                        value={formData?.nmcard || ""}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div>
                      <label htmlFor="expire">Expire le (MM / AA) *</label>
                      <input
                        type="text"
                        name="expire"
                        id="expire"
                        value={formData?.expire || ""}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                    <div>
                      <label htmlFor="cvc">Code de sécurité / CVC *</label>
                      <input
                        type="text"
                        name="cvc"
                        id="cvc"
                        value={formData?.cvc || ""}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                ) : paymentMethod === "PAYPAL" ? (
                  <div className="paypal-choice">
                    Vous paierez avec PayPal lors de l'étape suivante
                  </div>
                ) : (
                  <div className="cash-choice">
                    Vous serez facturé par l'établissement après votre
                    rendez-vous
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="checks-box" data-cy="MarketingConsent">
            <div className="stack">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingConsent}
                    onChange={(event) =>
                      setMarketingConsent(event.target.checked)
                    }
                  />
                }
                label={
                  <Typography className="text-regle text-justify">
                    Cochez cette case si vous souhaitez recevoir des e-mails de
                    Skipp contenant les dernières actualités de Skipp et des
                    offres pour des produits et services similaires. Vous pouvez
                    vous désinscrire à tout moment en cliquant sur le lien de
                    désabonnement dans les e-mails.
                  </Typography>
                }
              />
            </div>
            <div className="stack">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={venueMarketingConsent}
                    onChange={(event) =>
                      setVenueMarketingConsent(event.target.checked)
                    }
                  />
                }
                label={
                  <Typography className="text-regle text-justify">
                    Merci de cocher cette case pour autoriser le salon auprès
                    duquel vous avez réservé à vous envoyer des e-mails et SMS
                    concernant ses services.
                  </Typography>
                }
              />
            </div>
            <Typography variant="caption" className="caption text-justify">
              Vous pouvez modifier vos préférences en cliquant sur 'se
              désabonner' ou en contactant directement votre salon à propos des
              emails qui sont envoyés et sur l'utilisation de vos données
              personnelles. Pour en savoir plus, consultez notre{" "}
              <span
                className="text-blue-400 underline hover:no-underline cursor-pointer"
                onClick={() => window.open("/politique", "_blank")}
              >
                politique de confidentialité
              </span>
              .
            </Typography>
          </div>
          <button
            type="submit"
            className="flex items-center justify-center text-white"
            disabled={!active || loading}
          >
            {!active ? (
              "Payé"
            ) : loading ? (
              <img
                src={Lg1}
                alt="loading gif"
                className="w-8 h-8 min-w-8 min-h-8 rounded-full object-cover"
              />
            ) : (
              "Valider"
            )}
          </button>
        </form>
      </div>
      <div className="pannier-data">
        <div className="info-res-salon">
          <span>{reservation?.salon?.name}</span>
          <div className="about-app">
            <span className="time">
              {reservation?.start_time?.split(":").slice(0, 2).join(":")} -{" "}
              {reservation?.end_time?.split(":").slice(0, 2).join(":")}
            </span>

            <div className="date-long">
              <span className="capitalize">
                {reservation?.date && FormatDateToFrench(reservation?.date)}
              </span>
              <span>
                {duration?.hours && duration?.hours !== 0
                  ? `${duration?.hours} h `
                  : ""}{" "}
                {duration?.minutes && duration?.minutes !== 0
                  ? `${duration?.minutes} min`
                  : ""}
              </span>
            </div>
          </div>
          <div className="flex justify-center gap-2 text-center text-xs md:text-sm py-2 font-semibold">
            {reservation?.services?.map((ser) => ser?.name)?.join(", ")}
          </div>
          <div className="montant">
            <span>Montant à payer</span>
            <span>{totalPrice} Dhs</span>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default CheckoutPage;
