import React from "react";
import "./style.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

function Suggestions({ title }) {
  const items = [
    {
      title: "Coiffeurs",
      categorie: "Nail Services",
      color: 'coiffeur',
      icon: (
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          preserveAspectRatio="xMidYMid meet"
          fill="currentColor"
        >
          <path
            d="M11 16a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2zm4.106-15.447l1.788.894-3.658 7.316 7.317-3.657.894 1.788-10 5a34.665 34.665 0 01-2.326 1.034c-.468.188-.924.357-1.36.502-.985.328-1.831.527-2.533.564L5 16a3 3 0 112.992-2.777l.387-.151a32.69 32.69 0 001.88-.824l4.847-9.695zM5 12a1 1 0 100 2z"
            fillRule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      title: "Barbiers",
      categorie: "Hair Services",
      color: 'barbier',
      icon: (
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          preserveAspectRatio="xMidYMid meet"
          fill="currentColor"
        >
          <path
            d="M3 3c0 1.56.051 3.12.154 4.678l2.933 1.099.057-.08a6.764 6.764 0 013.881-2.616l.279-.062.406-.08 1.29.858 1.29-.859.406.081a6.764 6.764 0 014.16 2.677l.056.081 2.934-1.1a71.08 71.08 0 00.145-3.551L21 3h2c0 2.316-.11 4.63-.33 6.934l-.118 1.152-.168 1.51a11.08 11.08 0 01-2.927 6.353l-.25.258-.45.45a8 8 0 01-5.354 2.337L13.1 22h-2.2a8 8 0 01-5.44-2.133l-.217-.21-.45-.45a11.08 11.08 0 01-3.132-6.255l-.045-.355-.168-1.511a73.223 73.223 0 01-.439-6.928L1 3h2zm10.688 5.076L12 9.202l-1.689-1.126-.1.028a4.764 4.764 0 00-2.53 1.885l-.135.212-.617 1.028L3.334 9.88l.102.984.167 1.51a9.08 9.08 0 002.366 5.17l.238.248.45.45a6 6 0 003.962 1.75l.28.007h2.202a6 6 0 004.04-1.564l.202-.193.45-.45a9.079 9.079 0 002.56-5.077l.044-.34.167-1.51.101-.985-3.594 1.348-.617-1.028a4.763 4.763 0 00-2.665-2.097l-.101-.028zM14 12a2 2 0 011.995 1.85L16 14H8a2 2 0 012-2h4z"
            fillRule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      title: "Massage",
      categorie: "Massage",
      color: 'massage',
      icon: (
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          preserveAspectRatio="xMidYMid meet"
          fill="currentColor"
        >
          <path
            d="M18 1a6.226 6.226 0 015.14 7.022L21.5 19.5a4.077 4.077 0 01-4.036 3.5H17a3.62 3.62 0 01-3.602-3.98l.476-4.758a9.998 9.998 0 00-.067-2.516l-.882-5.734A4.403 4.403 0 0118 1zM6 1a4.402 4.402 0 015.102 4.8l-.027.212-.882 5.734c-.107.694-.14 1.397-.1 2.097l.033.42.476 4.757a3.62 3.62 0 01-3.41 3.975L7 23h-.465a4.077 4.077 0 01-4.002-3.3l-.033-.2L.86 8.022A6.226 6.226 0 016 1zm12 8l-2.247 2.246.03.196c.133.857.171 1.724.116 2.588l-.035.431-.476 4.758a1.62 1.62 0 001.464 1.775L17 21h.464c.98 0 1.817-.682 2.027-1.623l.03-.16 1.085-7.61L18 8.998zM6 9l-2.606 2.606 1.086 7.611.029.16c.198.889.956 1.547 1.865 1.617l.161.006H7l.147-.006A1.62 1.62 0 008.62 19.38l-.002-.08-.006-.081-.476-4.758-.035-.431a12 12 0 01.057-2.159l.058-.429.03-.196L6.002 9zm10.91-6.032a2.403 2.403 0 00-2.028 2.577l.02.163.474 3.087L17 7.171 17 5.001h2v2.17l1.96 1.961.2-1.393a4.226 4.226 0 00-3.489-4.766 2.403 2.403 0 00-.76-.005zm-9.821 0a2.403 2.403 0 00-.76.005 4.226 4.226 0 00-3.513 4.56l.024.206.2 1.393L5 7.17V5h2v2.171l1.624 1.624.474-3.087.02-.163a2.403 2.403 0 00-2.029-2.577z"
            fillRule="evenodd"
          ></path>
        </svg>
      ),
    },
    {
      title: "Instituts",
      categorie: "Facial Treatments",
      color: 'institut',
      icon: (
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          preserveAspectRatio="xMidYMid meet"
          fill="currentColor"
        >
          <path
            d="M2 2.5c3.307 0 5.642 1.49 6.829 4.374l.107.275.116.309-3.19 11.163a1 1 0 001.04 1.271l.118-.016 2.075-.415 1.22.488a.823.823 0 001.04-.396.677.677 0 00-.216-.857l-.086-.052-.5-.25.894-1.788.5.25a2.677 2.677 0 011.197 3.591 2.823 2.823 0 01-3.401 1.42l-.171-.061-.668-.268-1.491.3c-.391.077-.792.077-1.181-.002L6 21.78a3 3 0 01-2.104-3.538l.044-.17L6.944 7.554l-.067-.157c-.86-1.888-2.35-2.827-4.612-2.894L2 4.5v-2zm16.912 5.455l1.676 1.09c-1.04 1.602-2.427 2.455-4.088 2.455-1.582 0-2.915-.774-3.937-2.231l-.151-.224 1.676-1.09C14.781 9.02 15.561 9.5 16.5 9.5c.88 0 1.621-.422 2.28-1.352l.132-.193zM16.5 2.5c2.433 0 4.493 1.073 6.114 3.154l.192.255-1.612 1.182C19.909 5.34 18.369 4.5 16.5 4.5c-1.785 0-3.268.765-4.517 2.358l-.177.233-1.612-1.182C11.842 3.66 13.969 2.5 16.5 2.5z"
            fillRule="evenodd"
          ></path>
        </svg>
      ),
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="space-y-8">
      <div className="section-title center-text">
        <h2 className="title"> {title} </h2>
        <div className="section-title-line"> </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 max-w-7xl mx-auto px-4 service">
        {items?.map((item, index) => (
          <div key={index} className={`flex justify-center gap-4 items-center px-3 py-6 min-w-fit cursor-pointer rounded-md ${item?.color}`} onClick={()=>navigate('/sidelisting-page', { state:{ categorie: item?.categorie } })}>
            <div className={`${item?.color}-icon`}>{item.icon}</div>
            <span className={`${item?.color}-title`}>{item.title}</span>
            <ArrowForwardIcon className={`${item?.color}-arrow`}/>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Suggestions;
